import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-edit-profile',
	templateUrl: './edit-profile.component.html',
	styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

	submitted = false;
	editForm: FormGroup;

	encryptMode: boolean;
	encryptPswd: string;
	dencryptPswd;

	constructor(private fb: FormBuilder,
		public authService: AuthService,
		private actRoute: ActivatedRoute,
		private router: Router,
		public db: AngularFireDatabase,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		this.updateUserRegister();
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.getRegister(id).valueChanges().subscribe(data => {
			this.editForm.setValue(data)
			this.dencryptPswd = CryptoJS.AES.decrypt(this.password.value.trim(), this.email.value.trim()).toString(CryptoJS.enc.Utf8);

		});
	}

	updateUserRegister() {
		this.editForm = this.fb.group({
			fullName: ['', Validators.required],
			schoolName: ['', Validators.required],
			bday: ['', Validators.required],
			suburb: ['', Validators.required],
			postalCode: ['', Validators.required],
			state: ['', Validators.required],
			status: ['', Validators.required],
			email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			userType: ['', Validators.required],
			userId: ['', Validators.required],
		});
	}

	get fullName() {
		return this.editForm.get('fullName');
	}

	get schoolName() {
		return this.editForm.get('schoolName');
	}

	get bday() {
		return this.editForm.get('bday');
	}

	get suburb() {
		return this.editForm.get('suburb');
	}

	get postalCode() {
		return this.editForm.get('postalCode');
	}

	get state() {
		return this.editForm.get('state');
	}

	get email() {
		return this.editForm.get('email');
	}
	get password() {
		return this.editForm.get('password');
	}

	get e(): any {
		return this.editForm.controls;
	}

	convertText() {
		if (this.encryptMode) {
			this.encryptPswd = CryptoJS.AES.encrypt(this.dencryptPswd.trim(),
				this.editForm.controls.email.value.trim()).toString();
		}
	}

	updateForm() {
		let keepGoing = true;
		this.submitted = true;
		if (this.editForm.valid == true) {
			this.convertText();
			this.db.database.ref('user_register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().email;
						const b = data.val().password;
						if (keepGoing) {
							if (this.authService.currentUserEmail !== this.e.email.value) {
								if (a === this.e.email.value) {
									this.toastr.error('User already exist');
									return keepGoing = false;
								}
							}
						}
					});
					if (keepGoing === true) {
						this.convertText();
						this.db.object('/user_register/' + this.actRoute.snapshot.paramMap.get('id')).update({
							fullName: this.e.fullName.value,
							schoolName: this.e.schoolName.value,
							bday: this.e.bday.value,
							suburb: this.e.suburb.value,
							postalCode: this.e.postalCode.value,
							state: this.e.state.value,
							email: this.e.email.value,
							password: this.encryptPswd,
						});
						if (this.authService.currentUserEmail !== this.e.email.value) {
							this.authService.updateEmailAddress(this.e.email.value, this.dencryptPswd);
						}
						this.toastr.success(this.e['email'].value + ' updated successfully');
						this.router.navigate(['profile'])
					}
				})
		}
		else {
			this.toastr.error('Form is invalid');
		}
	}

}
