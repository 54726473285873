import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-addition-decimal-worksheet',
	templateUrl: './teach-addition-decimal-worksheet.component.html',
	styleUrls: ['./teach-addition-decimal-worksheet.component.css']
})
export class TeachAdditionDecimalWorksheetComponent implements OnInit {

	quizDetailsId: string;
	numberWhole: string;
	numberDecimal: string;
	nOfQuestions: string;

	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];
	resultval = [];
	resultList = [];
	sum;


	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(private db: AngularFireDatabase, 
		public router: Router,
		public toastr: ToastrService) { }

	ngOnInit() {
		this.sessionQuizDetails();
		this.sessionWholeNumber();
		this.sessionDecimalNumber();
		this.sessionProblemNumber();
		this.create();
	}

	sessionQuizDetails() {
		this.quizDetailsId = localStorage.getItem('quizDetailsToken');
	}

	sessionWholeNumber() {
		this.numberWhole = localStorage.getItem('numberWhole');
	}

	sessionDecimalNumber() {
		this.numberDecimal = localStorage.getItem('numberDecimal');
	}

	sessionProblemNumber() {
		this.nOfQuestions = localStorage.getItem('numberQuestion');
	}

	randomIntFromInterval(numberWhole, numberDecimal) {
		let precision = numberDecimal;
		return Math.floor(Math.random() * (numberWhole * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	create() {
		window.scroll(0, 0);
		const length = Number(this.nOfQuestions);

		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.list.push(i);
			const num1 = this.randomIntFromInterval(this.numberWhole, this.numberDecimal);
			const num2 = this.randomIntFromInterval(this.numberWhole, this.numberDecimal);

			this.list2.push(num1);
			this.list3.push(num2);

			const decimalCount = num => {
				const numStr = String(num);
				if (numStr.includes('.')) {
					return numStr.split('.')[1].length;
				};
				return 0;
			}
			this.list5.push(decimalCount(num1))
			this.list6.push(decimalCount(num2))

			if (this.list5[i] > this.list6[i] || this.list5[i] == this.list6[i]) {
				this.list4[i] = this.list5[i]
			} else {
				this.list4[i] = this.list6[i]
			}
			this.list7[i] = this.list4[i];
			this.list8[i] = this.list2[i] + this.list3[i];
			this.resultList[i] = parseFloat(this.list8[i].toFixed(this.list7[i]))
		}

	}
	
	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.db.list('teacher_questions').push({
				quizId: this.quizDetailsId,
				num1: this.list2[i],
				num2: this.list3[i],
				answer: this.resultList[i],
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}
}



