export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyBcd2y911WjYX1hxBLqh0lARsH-i6VHMV4",
		authDomain: "mathsquest-e3a40.firebaseapp.com",
		databaseURL: "https://mathsquest-e3a40.firebaseio.com",
		projectId: "mathsquest-e3a40",
		storageBucket: "mathsquest-e3a40.appspot.com",
		messagingSenderId: "138126341896",
		appId: "1:138126341896:web:21719eaad45e01b1a4debd",
		measurementId: "G-ZL7Q9CNKJ1"
	}
};
