import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-division-worksheet',
	templateUrl: './teach-division-worksheet.component.html',
	styleUrls: ['./teach-division-worksheet.component.css']
})
export class TeachDivisionWorksheetComponent implements OnInit {

	model: any = {
		shuffleQuiz: false
	};
	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	RegisterList: Auth[];
	keyCode: string;
	currentTeacherToken;
	quizName: string;
	teacherName: string;
	rangeDividend: number;
	rangeDivisor: number;
	numberQuestion: number;
	counter: number;
	seconds;
	questions = [];

	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	result = [];
	resultList = [];
	worksheet = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	key: string;
	
	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) { }

	ngOnInit() {
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	randomIntFromInterval1(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	randomIntFromInterval2(min, rangeDivisor) {
		return Math.floor(Math.random() * (rangeDivisor - min + 1) + min);
	}

	onCreate() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		
		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: 'Division',
						duration: this.seconds,
						totalQuiz: this.model.numberQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.makeQuestion();
					this.sessionQuizDetails();
				}
			});
	}

	makeQuestion() {
		this.worksheet = true;
		window.scroll(0, 0);
		for (let i = 0; i < this.model.numberQuestion; i++) {
			this.list.push(i);
			const j = this.randomIntFromInterval2(1, this.model.rangeDivisor);
			this.list2.push(j)
			const x = Math.floor(this.model.rangeDividend / this.list2[i])
			const y = this.randomIntFromInterval1(1, x);
			const z = y * this.list2[i];
			this.list5.push(z)
			this.resultList[i] = this.list5[i] / this.list2[i];
		}
	}

	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
			});
	}
	
	onSubmit() {
		for (let i = 0; i < this.model.numberQuestion; i++) {
			this.db.list('teacher_questions').push({
				quizId: localStorage.getItem('quizDetailsToken'),
				num1: this.list5[i],
				num2: this.list2[i],
				answer: this.resultList[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}

}
