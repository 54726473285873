import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import { browserRefresh } from '../../../app/app.component';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-test-worksheet',
	templateUrl: './test-worksheet.component.html',
	styleUrls: ['./test-worksheet.component.css']
})
export class TestWorksheetComponent implements OnInit {

	currentStudentToken;
	Result: Auth[];
	currentTime;

	quizID;
	quizKey;
	operatorType;
	totalQuiz;
	fracion = false;
	differentSymbol = false;
	studentRegister;
	counter;
	time;
	operatorSymbol;
	shuffleQuiz;

	Questions: Auth[];
	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];

	result = [];
	result1 = [];
	result2 = [];

	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	color = [];
	progress;
	score: number;
	interval;

	disappaer = false;
	timeStarted = false;

	public browserRefresh: boolean;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
		setInterval(() => {
			this.currentTime = new Date().toString().split(' ')[4];
		}, 1);
	}

	ngOnInit() {
		this.browserRefresh = browserRefresh;
		if (this.browserRefresh === true) {
			this.router.navigate(['/test-list'])
		}
		window.scroll(0, 0);
		this.score = 0;
		this.quizID = localStorage.getItem('codeKey')
		this.quizKey = localStorage.getItem('quizKey');
		this.operatorType = localStorage.getItem('operatorType')
		this.totalQuiz = localStorage.getItem('totalQ');
		this.shuffleQuiz = localStorage.getItem('shuffleQuiz')
		this.tokenStudentRegister();
		this.sessionTime();
		this.getOperator();
		this.countCalculate();
		this.questionList();
		if (this.operatorType === 'Addition - Fraction' || this.operatorType === 'Subtraction - Fraction' || this.operatorType === 'Multiplication - Fraction' || this.operatorType === 'Division - Fraction' || this.operatorType === 'Fraction-Operator') {
			this.fracion = true;
		} else {
			this.fracion = false;
		}
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem('currentStudentToken')
	}

	sessionTime() {
		this.time = localStorage.getItem('timeToSubmit');
		this.counter = Number(this.time)
	}

	getOperator() {
		if (this.operatorType === 'Addition' || this.operatorType === 'Addition - Fraction' || this.operatorType === 'Addition-Decimal') {
			this.operatorSymbol = '+';
		}
		if (this.operatorType === 'Subtraction' || this.operatorType === 'Subtraction - Fraction' || this.operatorType === 'Subtraction-Decimal') {
			this.operatorSymbol = '-';
		}
		if (this.operatorType === 'Multiplication' || this.operatorType === 'Multiplication - Fraction' || this.operatorType === 'Multiplication-Decimal') {
			this.operatorSymbol = '*';
		}
		if (this.operatorType === 'Division' || this.operatorType === 'Division - Fraction' || this.operatorType === 'Division-Decimal') {
			this.operatorSymbol = '÷';
		}
		if (this.operatorType === 'Basic-Operator' || this.operatorType === 'Fraction-Operator' || this.operatorType === 'Decimal-Operator') {
			this.differentSymbol = true;
		}
	}

	countCalculate() {
		this.interval = setInterval(() => {
			if (this.counter > 0) {
				this.timeStarted = true;
				this.counter--;
				if (this.counter === 0) {
					this.disappaer = true;
					clearInterval(this.interval);
					this.onSubmit();
				}
			}
		}, 1000);
	}

	questionList() {
		const s = this.authService.getQuestions();
		s.snapshotChanges().subscribe(data => {
			this.Questions = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().quizId;
				const Answer = item.payload.val().answer;
				const AnswerTop = item.payload.val().answerTop;
				const AnswerDown = item.payload.val().answerDown;
				if (this.quizID === b) {
					if (this.shuffleQuiz === 'true') {
						var currentIndex = this.Questions.length;
						var temporaryValue;
						var randomIndex;
						while (0 !== currentIndex) {
							randomIndex = Math.floor(Math.random() * currentIndex);
							currentIndex -= 1;
							temporaryValue = this.Questions[currentIndex];
							this.Questions[currentIndex] = this.Questions[randomIndex];
							this.Questions[randomIndex] = temporaryValue;
						}
					}
					this.Questions.push(a as Auth);
				}
			});
			for (let temp of this.Questions) {
				this.list.push(temp.answer)
				this.list2.push(temp.answerTop)
				this.list3.push(temp.answerDown)
				this.list4.push(temp.$key)
			}
		});
	}

	onSubmit() {
		this.submittedAnswers = true;
		window.scroll(0, 0);
		this.pauseTimer();
		for (let i = 0; i < this.totalQuiz; i++) {
			if (this.fracion == false) {
				if (this.result[i] == this.list[i]) {
					this.color[i] = 'green';
					this.score = this.score + 1;
					this.list5[i] = 'T'
				}
				if (this.result[i] != this.list[i]) {
					this.color[i] = 'red';
					this.list5[i] = 'F'
				}
				if (this.result[i] == undefined || this.result[i] == '') {
					this.result[i] = this.list[i];
					this.color[i] = 'red';
					this.list5[i] = 'F'
				}
			}
			if (this.fracion == true) {
				if (this.result1[i] == this.list2[i] && this.result2[i] == this.list3[i]) {
					this.color[i] = 'green';
					this.score = this.score + 1;
					this.list5[i] = 'T'
				}
				if (this.result1[i] != this.list2[i] || this.result2[i] != this.list3[i]) {
					this.color[i] = 'red';
					this.list5[i] = 'F'
				}
				if (this.result1[i] == undefined || this.result2[i] == undefined || this.result1[i] == '' || this.result2[i] == '') {
					this.result1[i] = this.list2[i];
					this.result2[i] = this.list3[i];
					this.color[i] = 'red';
					this.list5[i] = 'F'
				}
			}
		}

		let keepGoing = true;
		this.db.database.ref('student_testResult')
			.once('value')
			.then((snapshot) => {
				for (let i = 0; i < this.totalQuiz; i++) {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().reservation_id;
						const b = data.val().quest_id;
						if (a === this.quizKey && b === this.list4[i]) {
							this.list6[i] = data.key;
							this.db.object('student_testResult/' + this.list6[i]).update({
								answer: this.list5[i]
							});
							this.toastr.success('Successfully updated your result')
							keepGoing = false;
						}
					});
				}
				if (keepGoing === true) {
					for (let i = 0; i < this.totalQuiz; i++) {
						this.db.list('student_testResult').push({
							reservation_id: this.quizKey,
							quest_id: this.list4[i],
							answer: this.list5[i]
						});
						this.toastr.success('Successfully submitted your result')
					}
				}
			})
		this.db.object('student_test/' + this.quizKey).update({
			endTime: this.currentTime,
			progress: (this.score / this.totalQuiz) * 100
		});
	}

	onProgress() {
		this.resultSheet = true;
		window.scroll(0, 0);
		this.addDatabase();
	}

	addDatabase() {
		this.progress = (this.score / this.totalQuiz) * 100;
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}

}