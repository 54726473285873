import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: 'app-admin-users-list',
	templateUrl: './admin-users-list.component.html',
	styleUrls: ['./admin-users-list.component.css']
})
export class AdminUsersListComponent implements OnInit {

	RegisterStudentList: Auth[];
	RegisterTeacherList: Auth[];
	searchText;
	stateSubmit = false;


	pageRow;
	p = 1;
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase,
		public router: Router,
		private actRoute: ActivatedRoute,
		public toastr: ToastrService) {
	}

	ngOnInit() {
		this.studentList();
	}

	studentList() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterStudentList = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userType;
				if (b != 'Admin') {
					this.count += 1;
					this.RegisterStudentList.push(a as Auth);
				}
			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	view($key, type) {
		if (type === 'Teacher') {
			this.router.navigate(['admin-edit-teacher/' + $key])
		} else {
			this.router.navigate(['admin-edit-student/' + $key])
		}
	}

	statusActiveStudent($key) {
		this.stateSubmit = true;
		this.db.object('user_register/' + $key).update({
			status: 'Active'
		});
	}

	statusInactiveStudent($key) {
		this.stateSubmit = false;
		this.db.object('user_register/' + $key).update({
			status: 'Inactive'
		});
	}

	deleteStudent(student) {
		if (window.confirm('Are sure you want to delete this user ?')) {
			this.authService.DeleteStudent(student.$key)
			this.toastr.success('Delete user account');
		}
	}
}