import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-multiplication-decimal-worksheet',
	templateUrl: './teach-multiplication-decimal-worksheet.component.html',
	styleUrls: ['./teach-multiplication-decimal-worksheet.component.css']
})
export class TeachMultiplicationDecimalWorksheetComponent implements OnInit {

	quizDetailsId: string;
	numberWholeFirstFactor: string;
	numberDecimalFirstFactor: string;
	numberWholeSecondFactor: string;
	numberDecimalSecondFactor: string;
	nOfQuestions: string;

	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];
	resultList = [];
	sum;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(private db: AngularFireDatabase, 
		public router: Router,
		public toastr: ToastrService) { }

	ngOnInit() {
		this.sessionQuizDetails();
		this.sessionNumberWholeFirstFactor();
		this.sessionNumberDecimalFirstFactor();
		this.sessionNumberWholeSecondFactor();
		this.sessionNumberDecimalSecondFactor();
		this.sessionProblemNumber();
		this.create();
	}

	sessionQuizDetails() {
		this.quizDetailsId = localStorage.getItem('quizDetailsToken');
	}

	sessionNumberWholeFirstFactor() {
		this.numberWholeFirstFactor = localStorage.getItem('numberWholeFirstFactor');
	}

	sessionNumberDecimalFirstFactor() {
		this.numberDecimalFirstFactor = localStorage.getItem('numberDecimalFirstFactor');
	}

	sessionNumberWholeSecondFactor() {
		this.numberWholeSecondFactor = localStorage.getItem('numberWholeSecondFactor');
	}

	sessionNumberDecimalSecondFactor() {
		this.numberDecimalSecondFactor = localStorage.getItem('numberDecimalSecondFactor');
	}

	sessionProblemNumber() {
		this.nOfQuestions = localStorage.getItem('numberQuestion');
	}

	randomIntFromInterval(numberWhole, numberDecimal) {
		let precision = numberDecimal;
		return Math.floor(Math.random() * (numberWhole * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	create() {
		window.scroll(0, 0);
		const length = Number(this.nOfQuestions);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.list.push(i);
			const num1 = this.randomIntFromInterval(this.numberWholeFirstFactor, this.numberDecimalFirstFactor);
			const num2 = this.randomIntFromInterval(this.numberWholeSecondFactor, this.numberDecimalSecondFactor);

			this.list2.push(num1);
			this.list3.push(num2);

			const decimalCount = num => {
				const numStr = String(num);
				if (numStr.includes('.')) {
					return numStr.split('.')[1].length;
				};
				return 0;
			}
			this.list5.push(decimalCount(num1))
			this.list6.push(decimalCount(num2))
			this.list7[i] = this.list5[i] + this.list6[i];
			this.list8[i] = this.list2[i] * this.list3[i];
			this.resultList[i] = this.list8[i].toFixed(this.list7[i]);
		}
	}

	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.db.list('teacher_questions').push({
				quizId: this.quizDetailsId,
				num1: this.list2[i],
				num2: this.list3[i],
				answer: this.resultList[i],
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}

}



