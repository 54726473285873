import { Injectable } from '@angular/core';
import { Auth } from './auth';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	authState: any = null;
	userRegisterListRef: AngularFireList<any>;
	studentRegisterListRef: AngularFireList<any>;
	teacherRegisterListRef: AngularFireList<any>;
	adminRegisterListRef: AngularFireList<any>;
	studentRegisterRef: AngularFireObject<any>;
	teacherRegisterRef: AngularFireObject<any>;
	adminRegisterRef: AngularFireObject<any>;
	testListRef: AngularFireList<any>;
	guestTestListRef: AngularFireList<any>;
	userRegisterRef: AngularFireObject<any>;
	worksheetRef: AngularFireList<any>;
	quizDetailsRef: AngularFireList<any>;
	questionListRef: AngularFireList<any>;
	resultListRef: AngularFireList<any>;
	timesRef: AngularFireList<any>;
	timeRef: AngularFireObject<any>;
	statesRef: AngularFireList<any>;
	stateRef: AngularFireObject<any>;

	constructor(private afAuth: AngularFireAuth,
		private router: Router,
		private db: AngularFireDatabase,
		private http: HttpClient) {
		this.afAuth.authState.subscribe((auth) => {
			this.authState = auth;
		});
	}

	get isUserAnonymousLoggedIn(): boolean {
		return (this.authState !== null) ? this.authState.isAnonymous : false;
	}

	anonymousLogin() {
		return this.afAuth.auth.signInAnonymously()
			.then((user) => {
				this.authState = user
				localStorage.setItem('currentGuestToken', this.authState.user.uid)
			})
			.catch(error => console.log(error));
	}

	get currentUserId(): string {
		return (this.authState !== null) ? this.authState.uid : '';
	}

	get currentUser(): any {
		return (this.authState !== null) ? this.authState : null;
	}

	get isUserEmailLoggedIn(): boolean {
		if ((this.authState !== null) && (!this.isUserAnonymousLoggedIn)) {
			return true;
		} else {
			return false;
		}
	}

	get currentUserEmail(): string {
		return this.authState['email'];
	}

	signUpWithEmail(email: string, password: string) {
		return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
			.then((user) => {
				this.authState = user;

			})
			.then(() => {
				this.afAuth.auth.currentUser.sendEmailVerification()
					.catch(error => {
						throw error;
					})
			});
	}

	loginWithEmail(email: string, password: string) {
		return this.afAuth.auth.signInWithEmailAndPassword(email, password)
			.then((user) => {
				this.authState = user;
				firebase.auth().onAuthStateChanged(function (user) {
					if (user.emailVerified) {
						// console.log('Email is verified');
					} else {
						// console.log("Email is not verified");
					}
				})
			})
			.catch(error => {
				throw error;
			});
	}

	updateEmailAddress(email: string, password: string) {
		const currentUser = firebase.auth().currentUser;
		const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
		currentUser.reauthenticateWithCredential(credentials).then(function () {
			currentUser.updateEmail(email).then(function () {
				currentUser.sendEmailVerification().then(function () {
					// Email Sent
				}).catch(function (error) {
				});
			}).catch(function (error) {
			});
		});
	}

	signOutGuest(): void {
		localStorage.clear();
		this.afAuth.auth.signOut();
		localStorage.setItem('isLoggedInGuest', 'false');
		localStorage.removeItem('currentGuestToken');
		this.router.navigate(['/home']);
	}

	signOutStudent(): void {
		localStorage.clear();
		localStorage.setItem('isLoggedInStudent', 'false');
		localStorage.removeItem('currentStudentToken');
		this.router.navigate(['/home']);
	}

	signOutTeacher(): void {
		localStorage.clear();
		localStorage.setItem('isLoggedInTeacher', 'false');
		localStorage.removeItem('tokenTeacher');
		this.router.navigate(['/home']);
	}

	signOutAdmin(): void {
		localStorage.clear();
		localStorage.setItem('isLoggedInAdmin', 'false');
		localStorage.removeItem('currentAdminToken');
		this.router.navigate(['/home']);
	}

	resetPassword(email: string) {
		return this.afAuth.auth.sendPasswordResetEmail(email)
			.then(() => {

			})
			.catch((error) => {
				throw error;
			});
	}

	emailAuth(email: string) {
		return this.afAuth.auth.fetchSignInMethodsForEmail(email)
	}

	getUserRegisterList() {
		this.userRegisterListRef = this.db.list('user_register');
		return this.userRegisterListRef;
	}


	getRegisterStudentList() {
		this.studentRegisterListRef = this.db.list('user_register');
		return this.studentRegisterListRef;
	}

	getRegisterTeacherList() {
		this.teacherRegisterListRef = this.db.list('user_register');
		return this.teacherRegisterListRef;
	}

	getRegisterAdminList() {
		this.adminRegisterListRef = this.db.list('user_register');
		return this.adminRegisterListRef;
	}

	getRegister(id: string) {
		this.userRegisterRef = this.db.object('user_register/' + id);
		return this.userRegisterRef;
	}

	updateRegister(auth: Auth) {
		this.userRegisterRef.update({
			fullName: auth.fullName,
			schoolName: auth.schoolName,
			bday: auth.bday,
			suburb: auth.suburb,
			postalCode: auth.postalCode,
			state: auth.state,
			email: auth.email
		});
	}

	getDetails() {
		this.worksheetRef = this.db.list('student_quiz');
		return this.worksheetRef;
	}

	getTimeList() {
		this.timesRef = this.db.list('time_management');
		return this.timesRef;
	}

	getStateList() {
		this.statesRef = this.db.list('state_management');
		return this.statesRef;
	}

	getTime(id: string) {
		this.timeRef = this.db.object('time_management/' + id);
		return this.timeRef;
	}

	getState(id: string) {
		this.stateRef = this.db.object('state_management/' + id);
		return this.stateRef;
	}

	DeleteTime(id: string) {
		this.timeRef = this.db.object('time_management/' + id);
		this.timeRef.remove();
	}

	DeleteState(id: string) {
		this.stateRef = this.db.object('state_management/' + id);
		this.stateRef.remove();
	}

	getAddedTestList() {
		this.testListRef = this.db.list('student_test');
		return this.testListRef;
	}

	getGuestAddedTestList() {
		this.guestTestListRef = this.db.list('guest_test');
		return this.guestTestListRef;
	}

	getQuizDetails() {
		this.quizDetailsRef = this.db.list('teacher_quiz_details');
		return this.quizDetailsRef;
	}

	getQuestions() {
		this.questionListRef = this.db.list('teacher_questions');
		return this.questionListRef;
	}

	getTestResult() {
		this.resultListRef = this.db.list('student_testResult');
		return this.resultListRef;
	}

	GetStudent(id: string) {
		this.userRegisterRef = this.db.object('user_register/' + id);
		return this.userRegisterRef;
	}

	GetTeacher(id: string) {
		this.teacherRegisterRef = this.db.object('user_register/' + id);
		return this.teacherRegisterRef;
	}

	GetAdmin(id: string) {
		this.adminRegisterRef = this.db.object('user_register/' + id);
		return this.adminRegisterRef;
	}

	DeleteStudent(id: string) {
		this.studentRegisterRef = this.db.object('user_register/' + id);
		this.studentRegisterRef.remove();
	}

	DeleteTeacher(id: string) {
		this.teacherRegisterRef = this.db.object('user_register/' + id);
		this.teacherRegisterRef.remove();
	}

	httpGet(url) {
		return this.http.get(url);
	}

	httpPost(url, { }) {
		return this.http.post(url, { name: "Subrat" });
	}



	emailUser(url, data) {
		return this.http.post(url, data);
	}


}
