import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
  selector: 'app-teach-profile',
  templateUrl: './teach-profile.component.html',
  styleUrls: ['./teach-profile.component.css']
})
export class TeachProfileComponent implements OnInit {

	currentTeacherToken;
	RegisterList: Auth[];

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.tokenTeacherRegister();
		this.getUserRegister();
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	getUserRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

}