import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-teach-fraction-worksheet',
	templateUrl: './teach-fraction-worksheet.component.html',
	styleUrls: ['./teach-fraction-worksheet.component.css']
})
export class TeachFractionWorksheetComponent implements OnInit {

	model: any = { 
		positiveCheck: false,
		shuffleQuiz: false
	};
	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	RegisterList: Auth[];
	keyCode: string;
	currentTeacherToken;
	quizName: string;
	teacherName: string;
	operatorType: string;
	operatorName: string;
	mode: string;
	numberQuestion: string;
	counter: string;
	seconds;
	questions = [];

	key: string;

	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService) { }

	ngOnInit() {
		this.model.operatorType = 0;
		this.model.mode = 12;
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	create() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		
		localStorage.setItem('range', this.model.mode);
		localStorage.setItem('numberQuestion', this.model.numberQuestion);
		localStorage.setItem('counter', this.seconds);
		const positiveCheckBox = this.model.positiveCheck.toString();
		localStorage.setItem('positiveCheckBox', positiveCheckBox);
		if (this.model.operatorType === 0) {
			this.operatorName = 'Addition - Fraction'
		}
		else if (this.model.operatorType === 1) {
			this.operatorName = 'Subtraction - Fraction'
		}
		else if (this.model.operatorType === 2) {
			this.operatorName = 'Multiplication - Fraction'
		}
		else if (this.model.operatorType === 3) {
			this.operatorName = 'Division - Fraction'
		}
	}

	continue() {
		this.create();
		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: this.operatorName,
						duration: this.seconds,
						totalQuiz: this.model.numberQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.sessionQuizDetails();
				}
			});
	}

	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
				if (this.model.operatorType === 0) {
					this.router.navigate(['teacher-addition-fraction-worksheet'])
				}
				else if (this.model.operatorType === 1) {
					this.router.navigate(['teacher-subtraction-fraction-worksheet'])
				}
				else if (this.model.operatorType === 2) {
					this.router.navigate(['teacher-multiplication-fraction-worksheet'])
				}
				else if (this.model.operatorType === 3) {
					this.router.navigate(['teacher-division-fraction-worksheet'])
				}
			});
	}
}

