import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-decimal-operators',
	templateUrl: './decimal-operators.component.html',
	styleUrls: ['./decimal-operators.component.css']
})
export class DecimalOperatorsComponent implements OnInit {

	model: any = {
		ANumberQuestion: 0,
		SNumberQuestion: 0,
		MNumberQuestion: 0,
		DNumberQuestion: 0,
		positiveAnswerbox: false,
		shuffleQuiz: false
	}

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	RegisterList: Auth[];
	keyCode: string;
	currentTeacherToken;
	quizName: string;
	teacherName: string;
	operatorType;
	operatorName: string;
	numberWhole: number;
	numberDecimal: number;
	numberWholeDividend: string;
	numberDecimalDividend: string;
	numberWholeDivisor: string;
	numberDecimalDivisor: string;
	totalNoQuestion: number;
	questions = [];
	counter;
	seconds;

	key: string;

	num1 = [];
	num2 = [];

	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];
	list9 = [];
	resultval = [];
	resultList = [];
	sum;

	randomAnswer = [];
	value = [];
	decimalPlaceofAnswer = [];
	decimalPlacesofNum1 = [];
	answerWithDecimalPlaces = [];
	symbol = [];
	randomSymbol = [];

	worksheet = false;

	AList = [];
	SList = [];
	MList = [];
	DList = [];

	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) { }

	ngOnInit() {
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	randomIntFromInterval(numberWhole, numberDecimal) {
		let precision = numberDecimal;
		return Math.floor(Math.random() * (numberWhole * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	onCreate() {
		window.scroll(0, 0);
		if(this.model.ACheckBox === false) {
			this.model.ANumberWhole = 0;
			this.model.ANumberDecimal = 0;
			this.model.ANumberQuestion = 0;
		} if(this.model.SCheckBox === false) {
			this.model.SNumberWhole = 0;
			this.model.SNumberDecimal = 0;
			this.model.SNumberQuestion = 0;
		} if(this.model.MCheckBox === false) {
			this.model.MFNumberWhole = 0;
			this.model.MFNumberDecimal = 0;
			this.model.MSNumberWhole = 0;
			this.model.MSNumberDecimal = 0;
			this.model.MNumberQuestion = 0;
		} if(this.model.DCheckBox === false) {
			this.model.DFNumberWhole = 0;
			this.model.DFNumberDecimal = 0;
			this.model.DSNumberWhole = 0;
			this.model.DSNumberDecimal = 0;
			this.model.DNumberQuestion = 0;
		}

		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.totalNoQuestion = Number(this.model.ANumberQuestion) + Number(this.model.SNumberQuestion) + Number(this.model.MNumberQuestion) + Number(this.model.DNumberQuestion);
		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: 'Decimal-Operator',
						duration: this.seconds,
						totalQuiz: this.totalNoQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.makeQuestion();
					this.sessionQuizDetails();
				}
			});
	}

	makeQuestion() {
		this.worksheet = true;

		const ALenght = Number(this.model.ANumberQuestion);
		const SLenght = Number(this.model.SNumberQuestion);
		const MLenght = Number(this.model.MNumberQuestion);
		const DLenght = Number(this.model.DNumberQuestion);

		for (let i = 0; i < ALenght; i++) {
			this.AList.push('+')
		}
		for (let i = 0; i < SLenght; i++) {
			this.SList.push('-')
		}
		for (let i = 0; i < MLenght; i++) {
			this.MList.push('*')
		}
		for (let i = 0; i < DLenght; i++) {
			this.DList.push('/')
		}
		this.symbol = this.AList.concat(this.SList).concat(this.MList).concat(this.DList)

		let currentIndex = this.symbol.length, randomIndex;
		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			[this.symbol[currentIndex], this.symbol[randomIndex]] = [
				this.symbol[randomIndex], this.symbol[currentIndex]];
		}

		for (let i = 0; i < this.symbol.length; i++) {
			this.list.push(i);
			if (this.symbol[i] === '+') {
				this.num1[i] = this.randomIntFromInterval(this.model.ANumberWhole, this.model.ANumberDecimal);
				this.num2[i] = this.randomIntFromInterval(this.model.ANumberWhole, this.model.ANumberDecimal);

				this.list2[i] = this.num1[i];
				this.list3[i] = this.num2[i];

				const decimalCount = num => {
					const numStr = String(num);
					if (numStr.includes('.')) {
						return numStr.split('.')[1].length;
					};
					return 0;
				}
				this.list5[i] = (decimalCount(this.list2[i]))
				this.list6[i] = (decimalCount(this.list3[i]))

				if (this.list5[i] > this.list6[i] || this.list5[i] == this.list6[i]) {
					this.list4[i] = this.list5[i]
				} else {
					this.list4[i] = this.list6[i]
				}
				this.list7[i] = this.list4[i];
				this.list8[i] = eval(this.list2[i] + this.symbol[i] + this.list3[i]);
				this.resultList[i] = parseFloat(this.list8[i].toFixed(this.list7[i]))

			} else if (this.symbol[i] === '-') {
				if (this.model.positiveAnswerbox == true) {
					this.num1[i] = this.randomIntFromInterval(this.model.SNumberWhole, this.model.SNumberDecimal);
					const x = Math.random() * ((this.num1[i] - 1) - 0 + 1) + 0;
					this.num2[i] = x.toFixed(Math.log10(Number(this.model.SNumberDecimal)))
				} else {
					this.num1[i] = this.randomIntFromInterval(this.model.SNumberWhole, this.model.SNumberDecimal);
					this.num2[i] = this.randomIntFromInterval(this.model.SNumberWhole, this.model.SNumberDecimal);
				}				
				this.list2[i] = this.num1[i];
				this.list3[i] = this.num2[i];

				const decimalCount = num => {
					const numStr = String(num);
					if (numStr.includes('.')) {
						return numStr.split('.')[1].length;
					};
					return 0;
				}
				this.list5[i] = (decimalCount(this.list2[i]))
				this.list6[i] = (decimalCount(this.list3[i]))

				if (this.list5[i] > this.list6[i] || this.list5[i] == this.list6[i]) {
					this.list4[i] = this.list5[i]
				} else {
					this.list4[i] = this.list6[i]
				}
				this.list7[i] = this.list4[i];
				this.list8[i] = eval(this.list2[i] + this.symbol[i] + this.list3[i]);
				this.resultList[i] = parseFloat(this.list8[i].toFixed(this.list7[i]))
			} else if (this.symbol[i] === '*') {
				this.num1[i] = this.randomIntFromInterval(this.model.MFNumberWhole, this.model.MFNumberDecimal);
				this.num2[i] = this.randomIntFromInterval(this.model.MSNumberWhole, this.model.MSNumberDecimal);

				this.list2[i] = this.num1[i];
				this.list3[i] = this.num2[i];

				const decimalCount = num => {
					const numStr = String(num);
					if (numStr.includes('.')) {
						return numStr.split('.')[1].length;
					};
					return 0;
				}
				this.list5[i] = (decimalCount(this.list2[i]))
				this.list6[i] = (decimalCount(this.list3[i]))

				if (this.list5[i] > this.list6[i] || this.list5[i] == this.list6[i]) {
					this.list4[i] = this.list5[i]
				} else {
					this.list4[i] = this.list6[i]
				}
				this.list7[i] = this.list5[i] + this.list6[i];
				this.list8[i] = this.list2[i] * this.list3[i];
				this.resultList[i] = this.list8[i].toFixed(this.list7[i]);
			} else if (this.symbol[i] === '/') {
				this.num2[i] = this.randomIntFromInterval(this.model.DSNumberWhole, this.model.DSNumberDecimal);
				this.list3[i] = (this.num2[i]);

				const num1WithDigits = Number(((this.model.DFNumberWhole) - 1) + '.' + ((this.model.DFNumberDecimal) - 1))

				const answer = num1WithDigits / this.list3[i]

				const answerWhole = Math.floor(answer)
				const answerDecimal = (answer + "").split(".")[1]

				this.randomAnswer[i] = this.randomIntFromInterval(answerWhole, answerDecimal);

				const decimalCount = num => {
					const numStr = String(num);
					if (numStr.includes('.')) {
						return numStr.split('.')[1].length;
					};
					return 0;
				}
				this.list5.push(decimalCount(this.list3[i]))
				this.decimalPlacesofNum1[i] = decimalCount(num1WithDigits)

				if (this.decimalPlacesofNum1[i] > this.list5[i]) {
					this.decimalPlaceofAnswer[i] = this.decimalPlacesofNum1[i] - this.list5[i]
				}
				else {
					this.decimalPlaceofAnswer[i] = 0
				}

				this.value[i] = Math.pow(10, this.decimalPlaceofAnswer[i])
				this.answerWithDecimalPlaces[i] = parseInt('' + (this.randomAnswer[i] * this.value[i])) / this.value[i];

				this.num1[i] = this.answerWithDecimalPlaces[i] * this.list3[i];
				this.list2[i] = this.num1[i].toFixed(this.decimalPlacesofNum1[i])
				this.resultList[i] = this.answerWithDecimalPlaces[i];
			}
		}
	}

	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
			});
	}

	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < this.totalNoQuestion; i++) {
			this.db.list('teacher_questions').push({
				quizId: localStorage.getItem('quizDetailsToken'),
				num1: this.list2[i],
				num2: this.list3[i],
				answer: this.resultList[i],
				operatorSymbol: this.symbol[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}

}


