import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-admin-edit-profile',
	templateUrl: './admin-edit-profile.component.html',
	styleUrls: ['./admin-edit-profile.component.css']
})
export class AdminEditProfileComponent implements OnInit {

	submitted = false;
	editAdminForm: FormGroup;

	encryptMode: boolean;
	encryptPswd: string;
	dencryptPswd;

	constructor(private fb: FormBuilder,
		public router: Router,
		private actRoute: ActivatedRoute,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.GetAdmin(id).valueChanges().subscribe(data => {
			this.editAdminForm.setValue(data)
			this.dencryptPswd = CryptoJS.AES.decrypt(this.password.value.trim(), this.email.value.trim()).toString(CryptoJS.enc.Utf8);
		})
		this.adminRegister();
	}

	adminRegister() {
		this.editAdminForm = this.fb.group({
			fullName: ['', Validators.required],
			email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-z]{2,3}$')]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			userType: ['', Validators.required],
			userId: ['']
		});
	}

	get e(): any {
		return this.editAdminForm.controls;
	}

	get email() {
		return this.editAdminForm.get('email');
	}

	get password() {
		return this.editAdminForm.get('password');
	}

	convertText() {
		if (this.encryptMode) {
			this.encryptPswd = CryptoJS.AES.encrypt(this.dencryptPswd.trim(),
				this.editAdminForm.controls.email.value.trim()).toString();
		}
	}

	onUpdate(): void {
		let keepGoing = true;
		this.submitted = true;
		if (this.editAdminForm.valid == true) {
			this.convertText();
			this.db.database.ref('user_register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().email;
						const b = data.val().password;
						if (keepGoing) {
							if (this.authService.currentUserEmail !== this.e.email.value) {
								if (a === this.e.email.value) {
									this.toastr.error('User already exist');
									return keepGoing = false;
								}
							}
						}
					});
					if (keepGoing === true) {
						this.convertText();
						this.db.object('/user_register/' + this.actRoute.snapshot.paramMap.get('id')).update({
							fullName: this.e.fullName.value,
							email: this.e.email.value,
							password: this.encryptPswd,
						});
						if (this.authService.currentUserEmail !== this.e.email.value) {							
							this.authService.updateEmailAddress(this.e.email.value, this.dencryptPswd);
						}
						this.toastr.success(this.e['email'].value + ' updated successfully');
						this.router.navigate(['admin-profile'])
					}
				})
		}
		else {
			this.toastr.error('Form is invalid');
		}
	}

}

