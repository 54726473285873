import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-addition-worksheet',
	templateUrl: './teach-addition-worksheet.component.html',
	styleUrls: ['./teach-addition-worksheet.component.css']
})
export class TeachAdditionWorksheetComponent implements OnInit {

	model: any = {
		shuffleQuiz: false
	};
	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	currentTeacherToken;
	keyCode: string;
	Details: Auth[];
	RegisterList: Auth[];
	quizName: string;
	teacherName: string;
	numberQuestion: number;
	range: number;
	counter: number;
	seconds;
	questions = [];
	list = [];
	list2 = [];
	list3 = [];
	resultList = [];

	worksheet = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	key: string;

	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) { }

	ngOnInit() {
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	randomIntFromInterval(min, range) {
		return Math.floor(Math.random() * (range - min + 1) + min);
	}

	onCreate() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: 'Addition',
						duration: this.seconds,
						totalQuiz: this.model.numberQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.makeQuestion();
					this.sessionQuizDetails();
				}
			});
	}

	makeQuestion() {
		this.worksheet = true;
		window.scroll(0, 0);
		const length = Number(this.model.numberQuestion);
		for (let i = 0; i < this.model.numberQuestion; i++) {
			this.list.push(i);
			const k = this.randomIntFromInterval(0, this.model.range);
			this.list2[i] = k;
			const l = this.randomIntFromInterval(0, this.model.range);
			this.list3[i] = l;
			this.resultList[i] = this.list2[i] + this.list3[i];
		}
	}

	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
			});
	}

	onSubmit() {
		for (let i = 0; i < this.model.numberQuestion; i++) {
			this.db.list('teacher_questions').push({
				quizId: localStorage.getItem('quizDetailsToken'),
				num1: this.list2[i],
				num2: this.list3[i],
				answer: this.resultList[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}
}

