import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-edit-state-management',
	templateUrl: './edit-state-management.component.html',
	styleUrls: ['./edit-state-management.component.css']
})
export class EditStateManagementComponent implements OnInit {

	submitted = false;
	editStateForm: FormGroup;

	encryptMode: boolean;
	encryptPswdR: string;
	dencryptPswdL: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		private actRoute: ActivatedRoute,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.getState(id).valueChanges().subscribe(data => {
			this.editStateForm.setValue(data)
		})
		this.stateForm();
	}

	stateForm() {
		this.editStateForm = this.fb.group({
			state: ['', Validators.required],
		});
	}

	get e(): any {
		return this.editStateForm.controls;
	}

	get email() {
		return this.editStateForm.get('email');
	}


	ResetForm() {
		this.editStateForm.reset();
	}

	onUpdate(): void {
		this.submitted = true;
		let keepGoing = true;
		if (this.editStateForm.valid == true) {
			this.db.database.ref('state_management')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().state;
						if (keepGoing) {
							if (a === this.e.state.value) {
								this.toastr.error('State already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.object('/state_management/' + this.actRoute.snapshot.paramMap.get('id')).update({
							state: this.e.state.value,
						});
						this.toastr.success('State successfully updated');
						this.router.navigate(['admin-state-management']);
					}
				})
		}
	}

}
