import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-state-management',
	templateUrl: './state-management.component.html',
	styleUrls: ['./state-management.component.css']
})
export class StateManagementComponent implements OnInit {

	StateList: Auth[];
	searchText;
	order: string = 'state';
	reverse: boolean = false;

	model: any = {}
	p = 1
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	constructor(public authService: AuthService,
		private fb: FormBuilder,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
	}

	ngOnInit() {
		this.getTime();
	}

	getTime() {
		const s = this.authService.getStateList();
		s.snapshotChanges().subscribe(data => {
			this.StateList = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId;
				this.count += 1;
				this.StateList.push(a as Auth);

			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	deleteState(state) {
		if (window.confirm('Are sure you want to delete this state ?')) {
			this.authService.DeleteState(state.$key)
			this.toastr.success('Delete state');
		}
	}
}

