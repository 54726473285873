import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-admin-edit-teacher',
	templateUrl: './admin-edit-teacher.component.html',
	styleUrls: ['./admin-edit-teacher.component.css']
})
export class AdminEditTeacherComponent implements OnInit {

	submitted = false;
	editTeacherForm: FormGroup;

	encryptMode: boolean;
	encryptPswdR: string;
	dencryptPswdL: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		private actRoute: ActivatedRoute,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.GetTeacher(id).valueChanges().subscribe(data => {
			this.editTeacherForm.setValue(data)
			this.editTeacherForm.controls['password'].setValue(CryptoJS.AES.decrypt(this.password.value.trim(), this.email.value.trim()).toString(CryptoJS.enc.Utf8));
		})
		this.teacherRegister();
	}

	teacherRegister() {
		this.editTeacherForm = this.fb.group({
			fullName: ['', Validators.required],
			teleNo: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
			email: [{value: '', disabled : true}],
			password: [{value: '', disabled : true}],
			status: ['', Validators.required],
			userType: ['', Validators.required],
			userId: ['']
		});
		this.editTeacherForm.controls['email'].disable(); 
		this.editTeacherForm.controls['password'].disable();
	}

	get e(): any {
		return this.editTeacherForm.controls;
	}

	convertTextR() {
		if (this.encryptMode) {
			this.encryptPswdR = CryptoJS.AES.encrypt(this.editTeacherForm.controls.password.value.trim(),
				this.editTeacherForm.controls.email.value.trim()).toString();
		}
	}

	get email() {
		return this.editTeacherForm.get('email');
	}

	get password() {
		return this.editTeacherForm.get('password');
	}

	ResetForm() {
		this.editTeacherForm.reset();
	}

	onUpdate(): void {
		this.submitted = true;
		if (this.editTeacherForm.valid == true) {
			this.convertTextR();
			this.db.object('/user_register/' + this.actRoute.snapshot.paramMap.get('id')).update({
				fullName: this.e.fullName.value,
				teleNo: this.e.teleNo.value,
			});
			this.toastr.success(this.e['email'].value + ' updated successfully');
			this.router.navigate(['admin-user-list'])
		} else {
			this.toastr.error('Form is invalid');
		}
	}

}
