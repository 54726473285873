import { Component, OnInit } from '@angular/core';
@Component({
	selector: 'app-teach-operator',
	templateUrl: './teach-operator.component.html',
	styleUrls: ['./teach-operator.component.css']
})
export class TeachOperatorComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		
	}

}
