import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
	selector: 'app-teach-student-list',
	templateUrl: './teach-student-list.component.html',
	styleUrls: ['./teach-student-list.component.css']
})
export class TeachStudentListComponent implements OnInit {

	RegisterStudentList: Auth[];
	searchText;
	stateSubmit = false;

	pageRow;
	p = 1
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase) { }

	ngOnInit() {
		$(document).ready(function () {
			var trigger = $('.hamburger'),
				overlay = $('.overlay'),
				isClosed = false;

			trigger.click(function () {
				hamburger_cross();
			});

			function hamburger_cross() {

				if (isClosed == true) {
					overlay.hide();
					trigger.removeClass('is-open');
					trigger.addClass('is-closed');
					isClosed = false;
				} else {
					overlay.show();
					trigger.removeClass('is-closed');
					trigger.addClass('is-open');
					isClosed = true;
				}
			}

			$('[data-toggle="offcanvas"]').click(function () {
				$('#wrapper').toggleClass('toggled');
			});
		});
		this.studentList();
	}

	studentList() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterStudentList = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userType;
				if (b === 'Student') {
					this.count += 1;
					this.RegisterStudentList.push(a as Auth);
				}
			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}
	statusActivate($key) {
		this.stateSubmit = true;
		this.db.object('user_register/' + $key).update({
			status: 'Active'
		});
	}

	statusDeactivate($key) {
		this.stateSubmit = false;
		this.db.object('user_register/' + $key).update({
			status: 'Inactive'
		});
	}

	deleteStudent(student) {
		if (window.confirm('Are sure you want to delete this student ?')) {
			this.authService.DeleteStudent(student.$key)
		}
	}

}
