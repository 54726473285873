import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import { browserRefresh } from '../../../app/app.component';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-view-test',
	templateUrl: './view-test.component.html',
	styleUrls: ['./view-test.component.css']
})
export class ViewTestComponent implements OnInit {

	Result: Auth[];
	currentTime;

	quizID;
	quizKey;
	operatorType;
	totalQuiz;
	testName;
	startedTime;
	timeDuration;
	fracion = false;
	differentSymbol = false;
	studentRegister;
	counter;
	time;
	operatorSymbol;

	Questions: Auth[];
	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];

	result = [];
	result1 = [];
	result2 = [];

	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	color = [];
	progress;
	score: number;
	interval;

	disappaer = false;
	timeStarted = false;

	public browserRefresh: boolean;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
	}

	ngOnInit() {
		this.quizID = localStorage.getItem('codeKey')
		this.quizKey = localStorage.getItem('quizKey');
		this.operatorType = localStorage.getItem('operatorType')
		this.totalQuiz = localStorage.getItem('totalQ')
		this.testName = localStorage.getItem('testName');
		this.startedTime = localStorage.getItem('startedTime')
		this.timeDuration = localStorage.getItem('timeDuration')
		this.getOperator();
		this.questionList();
		if (this.operatorType === 'Addition - Fraction' || this.operatorType === 'Subtraction - Fraction' || this.operatorType === 'Multiplication - Fraction' || this.operatorType === 'Division - Fraction' || this.operatorType === 'Fraction-Operator') {
			this.fracion = true;
		} else {
			this.fracion = false;
		}
	}

	getOperator() {
		if (this.operatorType === 'Addition' || this.operatorType === 'Addition - Fraction' || this.operatorType === 'Addition-Decimal') {
			this.operatorSymbol = '+';
		}
		if (this.operatorType === 'Subtraction' || this.operatorType === 'Subtraction - Fraction' || this.operatorType === 'Subtraction-Decimal') {
			this.operatorSymbol = '-';
		}
		if (this.operatorType === 'Multiplication' || this.operatorType === 'Multiplication - Fraction' || this.operatorType === 'Multiplication-Decimal') {
			this.operatorSymbol = '*';
		}
		if (this.operatorType === 'Division' || this.operatorType === 'Division - Fraction' || this.operatorType === 'Division-Decimal') {
			this.operatorSymbol = '÷';
		}
		if (this.operatorType === 'Basic-Operator' || this.operatorType === 'Fraction-Operator' || this.operatorType === 'Decimal-Operator') {
			this.differentSymbol = true;
		}
	}

	questionList() {
		const s = this.authService.getQuestions();
		s.snapshotChanges().subscribe(data => {
			this.Questions = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().quizId;
				if (this.quizID === b) {
					this.Questions.push(a as Auth);
				}
			});

		});
	}

	convertToPdf() {
		const singlePageFileName = this.testName + ".pdf"
		let html1 = document.querySelector('.printformClass');
		html2canvas(document.querySelector(".printformClass")).then(canvas => {

			var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

			var imgData = canvas.toDataURL("image/jpeg", 1.0);
			pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
			pdf.save(singlePageFileName);
		});

	}


	getPDF() {
		const multiPageFileName = this.testName + ".pdf"
		html2canvas(document.querySelector(".printformClass")).then(function (canvas) {
			canvas.getContext('2d');
			var HTML_Width = canvas.width;
			var HTML_Height = canvas.height;
			var top_left_margin = 15;
			var PDF_Width = HTML_Width + (top_left_margin * 2);
			var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
			var canvas_image_width = HTML_Width;
			var canvas_image_height = HTML_Height;
			var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
			var imgData = canvas.toDataURL("image/jpeg", 1.0);
			var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
			pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
			for (var i = 1; i <= totalPDFPages; i++) {
				pdf.addPage([PDF_Width, PDF_Height]);
				let margin = -(PDF_Height * i) + (top_left_margin * 4);
				if (i > 1) {
					margin = margin + i * 8;
				}
				pdf.addImage(imgData, 'JPG', top_left_margin, margin, canvas_image_width, canvas_image_height);
			}
			pdf.save(multiPageFileName);
		});
	}
}