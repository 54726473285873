import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	currentStudentToken;
	RegisterList: Auth[];

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.tokenStudentRegister();
		this.getUserRegister();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem('currentStudentToken')
	}

	getUserRegister() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentStudentToken === b) {
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}


}
