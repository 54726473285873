import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StudentAuthGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.isLoggedInStudent) {
			return true;
		}
		localStorage.setItem('isLoggedInStudent', 'false');
		localStorage.removeItem('currentStudentToken');

		this.router.navigate(['/login']);
		return false;
	}

	get isLoggedInStudent(): boolean {
		let status = false;
		if (localStorage.getItem('isLoggedInStudent') == "true") {
			status = true;
		}
		else {
			status = false;
		}
		return status;
	}
}

