import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-basic-operators',
	templateUrl: './basic-operators.component.html',
	styleUrls: ['./basic-operators.component.css']
})
export class BasicOperatorsComponent implements OnInit {

	model: any = {
		ANumberQuestion: 0,
		SNumberQuestion: 0,
		MNumberQuestion: 0,
		DNumberQuestion: 0,
		positiveAnswerbox: false,
		shuffleQuiz: false
	}

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	currentTeacherToken;
	keyCode: string;
	Details: Auth[];
	RegisterList: Auth[];
	quizName: string;
	teacherName: string;
	totalNoQuestion: number;
	range: number;
	counter: number;
	seconds;
	questions = [];

	list = [];
	list2 = [];
	list3 = [];

	symbol = [];
	randomSymbol = [];
	resultList = [];

	worksheet = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	key: string;

	AList = [];
	SList = [];
	MList = [];
	DList = [];

	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) { }

	ngOnInit() {
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	randomIntFromInterval(min, range) {
		return Math.floor(Math.random() * (range - min + 1) + min);
	}

	randomIntFromInterval1(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	randomIntFromInterval2(min, rangeDivisor) {
		return Math.floor(Math.random() * (rangeDivisor - min + 1) + min);
	}

	onCreate() {
		window.scroll(0, 0);
		if (this.model.ACheckBox === false) {
			this.model.ARange = 0;
			this.model.ANumberQuestion = 0;
		} if (this.model.SCheckBox === false) {
			this.model.SRange = 0;
			this.model.SNumberQuestion = 0;
		} if (this.model.MCheckBox === false) {
			this.model.MFRange = 0;
			this.model.MSRange = 0;
			this.model.MNumberQuestion = 0;
		} if (this.model.DCheckBox === false) {
			this.model.DFRange = 0;
			this.model.DSRange = 0;
			this.model.DNumberQuestion = 0;
		}

		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.totalNoQuestion = Number(this.model.ANumberQuestion) + Number(this.model.SNumberQuestion) + Number(this.model.MNumberQuestion) + Number(this.model.DNumberQuestion);
		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: 'Basic-Operator',
						duration: this.seconds,
						totalQuiz: this.totalNoQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.makeQuestion();
					this.sessionQuizDetails();
				}
			});
	}

	makeQuestion() {
		this.worksheet = true;
		const ALenght = Number(this.model.ANumberQuestion);
		const SLenght = Number(this.model.SNumberQuestion);
		const MLenght = Number(this.model.MNumberQuestion);
		const DLenght = Number(this.model.DNumberQuestion);

		for (let i = 0; i < ALenght; i++) {
			this.AList.push('+')
		}
		for (let i = 0; i < SLenght; i++) {
			this.SList.push('-')
		}
		for (let i = 0; i < MLenght; i++) {
			this.MList.push('*')
		}
		for (let i = 0; i < DLenght; i++) {
			this.DList.push('/')
		}
		this.symbol = this.AList.concat(this.SList).concat(this.MList).concat(this.DList)

		let currentIndex = this.symbol.length, randomIndex;
		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			[this.symbol[currentIndex], this.symbol[randomIndex]] = [
				this.symbol[randomIndex], this.symbol[currentIndex]];
		}

		for (let i = 0; i < this.symbol.length; i++) {
			this.list.push(i);
			if (this.symbol[i] === '+') {
				const k = this.randomIntFromInterval(0, this.model.ARange);
				this.list2[i] = k;
				const l = this.randomIntFromInterval(0, this.model.ARange);
				this.list3[i] = l;
			} else if (this.symbol[i] === '-') {
				if (this.model.positiveAnswerbox == true) {
					const k = this.randomIntFromInterval(0, this.model.SRange);
					this.list2[i] = k;
					const l = this.randomIntFromInterval(0, k - 1);
					this.list3[i] = l;
				} else {
					const k = this.randomIntFromInterval(0, this.model.SRange);
					this.list2[i] = k;
					const l = this.randomIntFromInterval(0, this.model.SRange);
					this.list3[i] = l;
				}
			} else if (this.symbol[i] === '*') {
				const k = this.randomIntFromInterval(0, this.model.MFRange);
				this.list2[i] = k;
				const l = this.randomIntFromInterval(0, this.model.MSRange);
				this.list3[i] = l;
			} else if (this.symbol[i] === '/') {
				const j = this.randomIntFromInterval2(1, this.model.DSRange);
				this.list3.push(j)
				const x = Math.floor(this.model.DFRange / this.list3[i])
				const y = this.randomIntFromInterval1(1, x);
				const z = y * this.list3[i];
				this.list2.push(z)
			}
			this.resultList[i] = eval(this.list2[i] + this.symbol[i] + this.list3[i]);
		}
	}

	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
			});
	}

	onSubmit() {
		for (let i = 0; i < this.totalNoQuestion; i++) {
			this.db.list('teacher_questions').push({
				quizId: localStorage.getItem('quizDetailsToken'),
				num1: this.list2[i],
				num2: this.list3[i],
				answer: this.resultList[i],
				operatorSymbol: this.symbol[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}
}

