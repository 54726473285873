import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-admin-create-user',
	templateUrl: './admin-create-user.component.html',
	styleUrls: ['./admin-create-user.component.css']
})
export class AdminCreateUserComponent implements OnInit {

	model: any = {};
	submitted = false;
	userRegisterForm: FormGroup;
	form: NgForm;

	StateList: Auth[];
	order: string = 'state';
	reverse: boolean = false;

	encryptMode: boolean;
	encryptPswdR: string;

	isTextFieldType: boolean;
	
	constructor(private fb: FormBuilder,
		public router: Router,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		this.getState();
	}

	togglePasswordFieldType() {
		this.isTextFieldType = !this.isTextFieldType;
	}

	getState() {
		const s = this.authService.getStateList();
		s.snapshotChanges().subscribe(data => {
			this.StateList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.StateList.push(a as Auth);

			});
		});
	}

	reset(f: NgForm) {
		f.resetForm();
	}

	convertTextR() {
		if (this.encryptMode) {
			this.encryptPswdR = CryptoJS.AES.encrypt(this.model.password.trim(),
				this.model.email.trim()).toString();
		}
	}

	onSignUpStudent(form: NgForm): void {
		this.submitted = true;
		let keepGoing = true;
		this.convertTextR();
		if (form.valid == true) {
			this.db.database.ref('user_register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().email;
						const b = data.val().password;
						if (keepGoing) {
							if (a === this.model.email) {
								this.toastr.error('User already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.list('/user_register').push({
							fullName: this.model.fullName,
							schoolName: this.model.schoolName,
							bday: this.model.bday,
							suburb: this.model.suburb,
							postalCode: this.model.postalCode,
							state: this.model.state,
							status: 'Active',
							email: this.model.email,
							password: this.encryptPswdR,
							userType: this.model.userType,
							userId: ''
						});
						this.toastr.success(this.model.email + ' successfully register');
						this.reset(form)
					}
				})
		}
	}

	onSignUpTeacher(form: NgForm) {
		this.submitted = true;
		let keepGoing = true;
		this.convertTextR();
		if (form.valid == true) {
			this.db.database.ref('user_register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().email;
						const b = data.val().password;
						if (keepGoing) {
							if (a === this.model.email) {
								this.toastr.error('User already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.list('/user_register').push({
							fullName: this.model.fullName,
							teleNo: this.model.teleNo,
							email: this.model.email,
							password: this.encryptPswdR,
							userType: this.model.userType,
							status: 'Active',
							userId: ''
						});
						this.toastr.success(this.model.email + ' successfully register');
						this.reset(form)
					}
				})
		}
	}

}
