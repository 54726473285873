import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service'
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	model: any = {};

	constructor(public authService: AuthService,
		public toastr: ToastrService,
		public db: AngularFireDatabase,
	) { }

	ngOnInit() {
		this.getEmailAdmin();
	}

	getEmailAdmin(): void {
		this.db.database.ref('user_register')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().email;
					const b = data.val().password;
					const c = data.val().userType;
					if (c === 'Admin') {
						localStorage.setItem('adminEmail', a);
					}
				});
			});
	}

	sendMessage(f: NgForm) {
		this.toastr.success('Email was sent');
		let user = {
			firstName: this.model.firstName,
			lastName: this.model.lastName,
			email: this.model.email,
			message: this.model.message,
			adminEmail: localStorage.getItem('adminEmail'),
			emailType: 'contact',
		}
		this.authService.emailUser("https://us-central1-mathsquest-e3a40.cloudfunctions.net/app/sendmail", user).subscribe(
			data => {
				let res: any = data;
			},
		);
		f.resetForm();
	}

}
