import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-edit-student',
	templateUrl: './teach-edit-student.component.html',
	styleUrls: ['./teach-edit-student.component.css']
})
export class TeachEditStudentComponent implements OnInit {

	submitted = false;
	editStudentForm: FormGroup;

	encryptMode: boolean;
	encryptPswdR: string;
	dencryptPswdL: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		private actRoute: ActivatedRoute,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$(function () {
			$('.form-holder').delegate('input', 'focus', function () {
				$('.form-holder').removeClass('active');
				$(this).parent().addClass('active');
			})
		});
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		$(document).ready(function () {
			var trigger = $('.hamburger'),
				overlay = $('.overlay'),
				isClosed = false;

			trigger.click(function () {
				hamburger_cross();
			});
			function hamburger_cross() {

				if (isClosed == true) {
					overlay.hide();
					trigger.removeClass('is-open');
					trigger.addClass('is-closed');
					isClosed = false;
				} else {
					overlay.show();
					trigger.removeClass('is-closed');
					trigger.addClass('is-open');
					isClosed = true;
				}
			}
			$('[data-toggle="offcanvas"]').click(function () {
				$('#wrapper').toggleClass('toggled');
			});
		});
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.GetStudent(id).valueChanges().subscribe(data => {
			this.editStudentForm.setValue(data)
			this.editStudentForm.controls['password'].setValue(CryptoJS.AES.decrypt(this.password.value.trim(), this.email.value.trim()).toString(CryptoJS.enc.Utf8));
		})
		this.userRegister();
	}

	userRegister() {
		this.editStudentForm = this.fb.group({
			fullName: ['', Validators.required],
			schoolName: ['', Validators.required],
			bday: ['', Validators.required],
			suburb: ['', Validators.required],
			postalCode: ['', Validators.required],
			state: ['', Validators.required],
			email: [{value: '', disabled : true}],
			password: [{value: '', disabled : true}],
			status: ['', Validators.required],
			userType: ['', Validators.required],
			userId: ['']
		});
		this.editStudentForm.controls['email'].disable(); 
		this.editStudentForm.controls['password'].disable();
	}

	get e(): any {
		return this.editStudentForm.controls;
	}

	convertTextR() {
		if (this.encryptMode) {
			this.encryptPswdR = CryptoJS.AES.encrypt(this.editStudentForm.controls.password.value.trim(),
				this.editStudentForm.controls.email.value.trim()).toString();
		}
	}

	get email() {
		return this.editStudentForm.get('email');
	}

	get password() {
		return this.editStudentForm.get('password');
	}

	ResetForm() {
		this.editStudentForm.reset();
	}

	onUpdate(): void {
		this.submitted = true;
		if (this.editStudentForm.valid == true) {
			this.convertTextR();
			this.db.object('/user_register/' + this.actRoute.snapshot.paramMap.get('id')).update({
				fullName: this.e.fullName.value,
				schoolName: this.e.schoolName.value,
				bday: this.e.bday.value,
				suburb: this.e.suburb.value,
				postalCode: this.e.postalCode.value,
				state: this.e.state.value,
				email: this.e.email.value,
				password: this.encryptPswdR
			});
			this.toastr.success(this.e['email'].value + ' successfully updated');
			this.router.navigate(['teacher-student-list'])
		} else {
			this.toastr.error('Form is invalid');
		}
	}

}
