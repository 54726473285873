import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service'
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
	selector: 'app-admin-login',
	templateUrl: './admin-login.component.html',
	styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

	model: any = {};
	submitted = false;
	loginForm: FormGroup;
	resetPassword = false;

	encryptMode: boolean;
	encryptPswdL: string;
	dencryptPswdL: string;

	email: string;
	password: string;
	userType: string;

	sheet = false;

	constructor(private fb: FormBuilder,
		public router: Router,
		private afAuth: AngularFireAuth,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
	}

	convertTextL() {
		if (this.encryptMode) {
			this.encryptPswdL = CryptoJS.AES.encrypt(this.model.password.trim(),
				this.model.email.trim()).toString();
		}
	}

	onLoginEmailAdmin(): void {
		this.submitted = true;
		let keepGoing = true;
		this.authService.loginWithEmail(this.model.email, this.model.password)
			.then(() => {
				this.db.database.ref('user_register')
					.once('value')
					.then((snapshot) => {
						snapshot.forEach(data => {
							const key = data.key;
							const a = data.val().userType;
							const b = data.val().userId;
							if (keepGoing) {
								if (a === 'Admin' && b === this.afAuth.auth.currentUser.uid) {
									localStorage.setItem('isLoggedInAdmin', "true");
									localStorage.setItem('currentAdminToken', this.afAuth.auth.currentUser.uid);
									this.toastr.success(this.model.email + ' successfully login');
									this.router.navigate(['admin-user-list']);
									return keepGoing = false;
								}
							}
						});
						if (keepGoing === true) {
							this.toastr.error('Please use valid user sign in form to login to the system.');
						}
					});
			})
			.catch(error => {
				this.toastr.error(error.message);
			});
	}

}
