import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';

import { HomeComponent } from './home/home.component';
import { OperatorsComponent } from './student/operators/operators.component';
import { ProfileComponent } from './student/profile/profile.component';
import { EditProfileComponent } from './student/edit-profile/edit-profile.component';
import { PricingComponent } from './student/pricing/pricing.component';
import { SelfTestResultComponent } from './student/self-test-result/self-test-result.component';
import { TeacherAssignTestResultComponent } from './student/teacher-assign-test-result/teacher-assign-test-result.component';
import { MultiplicationWorksheetComponent } from './student/multiplication-worksheet/multiplication-worksheet.component';
import { SubtractionWorksheetComponent } from './student/subtraction-worksheet/subtraction-worksheet.component';
import { AdditionWorksheetComponent } from './student/addition-worksheet/addition-worksheet.component';
import { DivisionWorksheetComponent } from './student/division-worksheet/division-worksheet.component';
import { FractionWorksheetComponent } from './student/fraction-worksheet/fraction-worksheet.component';
import { AdditionFractionWorksheetComponent } from './student/addition-fraction-worksheet/addition-fraction-worksheet.component';
import { MultiplicationFractionWorksheetComponent } from './student/multiplication-fraction-worksheet/multiplication-fraction-worksheet.component';
import { SubtractionFractionWorksheetComponent } from './student/subtraction-fraction-worksheet/subtraction-fraction-worksheet.component';
import { DivisionFractionWorksheetComponent } from './student/division-fraction-worksheet/division-fraction-worksheet.component';
import { DecimalWorksheetComponent } from './student/decimal-worksheet/decimal-worksheet.component';
import { AdditionDecimalWorksheetComponent } from './student/addition-decimal-worksheet/addition-decimal-worksheet.component';
import { SubtractionDecimalWorksheetComponent } from './student/subtraction-decimal-worksheet/subtraction-decimal-worksheet.component';
import { MultiplicationDecimalWorksheetComponent } from './student/multiplication-decimal-worksheet/multiplication-decimal-worksheet.component';
import { DivisionDecimalWorksheetComponent } from './student/division-decimal-worksheet/division-decimal-worksheet.component';
import { TestListComponent } from './student/test-list/test-list.component';
import { TestWorksheetComponent } from './student/test-worksheet/test-worksheet.component';
import { StudentAuthGuard } from './student/guards/student-auth.guard';

import { BasicOperatorsComponent } from './teacher/basic-operators/basic-operators.component';
import { FractionOperatorsComponent } from './teacher/fraction-operators/fraction-operators.component';
import { DecimalOperatorsComponent } from './teacher/decimal-operators/decimal-operators.component';

import { TeachOperatorComponent } from './teacher/teach-operator/teach-operator.component';
import { TeachTestListComponent } from './teacher/teach-test-list/teach-test-list.component';
import { TeachStudentListComponent } from './teacher/teach-student-list/teach-student-list.component';
import { TeachEditStudentComponent } from './teacher/teach-edit-student/teach-edit-student.component';
import { TeachConductedTestListComponent } from './teacher/teach-conducted-test-list/teach-conducted-test-list.component';
import { TeachTestResultComponent } from './teacher/teach-test-result/teach-test-result.component';
import { TeachProfileComponent } from './teacher/teach-profile/teach-profile.component';
import { TeachEditProfileComponent } from './teacher/teach-edit-profile/teach-edit-profile.component';
import { TeachAdditionWorksheetComponent } from './teacher/teach-addition-worksheet/teach-addition-worksheet.component';
import { TeachAdditionFractionWorksheetComponent } from './teacher/teach-addition-fraction-worksheet/teach-addition-fraction-worksheet.component';
import { TeachAdditionDecimalWorksheetComponent } from './teacher/teach-addition-decimal-worksheet/teach-addition-decimal-worksheet.component';
import { TeachDivisionWorksheetComponent } from './teacher/teach-division-worksheet/teach-division-worksheet.component';
import { TeachDivisionFractionWorksheetComponent } from './teacher/teach-division-fraction-worksheet/teach-division-fraction-worksheet.component';
import { TeachDivisionDecimalWorksheetComponent } from './teacher/teach-division-decimal-worksheet/teach-division-decimal-worksheet.component';
import { TeachMultiplicationWorksheetComponent } from './teacher/teach-multiplication-worksheet/teach-multiplication-worksheet.component';
import { TeachMultiplicationFractionWorksheetComponent } from './teacher/teach-multiplication-fraction-worksheet/teach-multiplication-fraction-worksheet.component';
import { TeachMultiplicationDecimalWorksheetComponent } from './teacher/teach-multiplication-decimal-worksheet/teach-multiplication-decimal-worksheet.component';
import { TeachSubtractionWorksheetComponent } from './teacher/teach-subtraction-worksheet/teach-subtraction-worksheet.component';
import { TeachSubtractionFractionWorksheetComponent } from './teacher/teach-subtraction-fraction-worksheet/teach-subtraction-fraction-worksheet.component';
import { TeachSubtractionDecimalWorksheetComponent } from './teacher/teach-subtraction-decimal-worksheet/teach-subtraction-decimal-worksheet.component';
import { TeachFractionWorksheetComponent } from './teacher/teach-fraction-worksheet/teach-fraction-worksheet.component';
import { TeachDecimalWorksheetComponent } from './teacher/teach-decimal-worksheet/teach-decimal-worksheet.component';
import { ViewTestComponent } from './teacher/view-test/view-test.component';
import { TeachAuthGuard } from './teacher/guards/teach-auth.guard';

import { AdminAuthGuard } from './admin/guards/admin-auth.guard';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminUsersListComponent } from './admin/admin-users-list/admin-users-list.component';
import { AdminEditStudentComponent } from './admin/admin-edit-student/admin-edit-student.component';
import { AdminEditTeacherComponent } from './admin/admin-edit-teacher/admin-edit-teacher.component';
import { TimeManagementComponent } from './admin/time-management/time-management.component';
import { StateManagementComponent } from './admin/state-management/state-management.component';
import { EditStateManagementComponent } from './admin/edit-state-management/edit-state-management.component';
import { EditTimeManagementComponent } from './admin/edit-time-management/edit-time-management.component';
import { AdminCreatedTestComponent } from './admin/admin-created-test/admin-created-test.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { AdminEditProfileComponent } from './admin/admin-edit-profile/admin-edit-profile.component';
import { AdminCreateUserComponent } from './admin/admin-create-user/admin-create-user.component';
import { AddTimeManagementComponent } from './admin/add-time-management/add-time-management.component';

import { AuthActionComponent } from './auth-action/auth-action.component';

import { GuestCreateComponent } from './guest/guest-create/guest-create.component';
import { GuestTestListComponent } from './guest/guest-test-list/guest-test-list.component';
import { GuestTestWorksheetComponent } from './guest/guest-test-worksheet/guest-test-worksheet.component';
import { GuestAuthGuard } from './guest/guards/guest-auth.guard';

const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'register', component: RegisterComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'forget-password', component: ForgetPasswordComponent },
	{ path: 'auth-action', component: AuthActionComponent },

	{ path: 'operators', component: OperatorsComponent, canActivate: [StudentAuthGuard] },
	{ path: 'profile', component: ProfileComponent, canActivate: [StudentAuthGuard] },
	{ path: 'edit-profile/:id', component: EditProfileComponent, canActivate: [StudentAuthGuard] },
	{ path: 'pricing', component: PricingComponent, canActivate: [StudentAuthGuard] },
	{ path: 'self-test-result', component: SelfTestResultComponent, canActivate: [StudentAuthGuard] },
	{ path: 'teacher-assign-test-result', component: TeacherAssignTestResultComponent, canActivate: [StudentAuthGuard] },
	{ path: 'multiplication-worksheet', component: MultiplicationWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'subtraction-worksheet', component: SubtractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'addition-worksheet', component: AdditionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'division-worksheet', component: DivisionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'fraction-worksheet', component: FractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'addition-fraction-worksheet', component: AdditionFractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'multiplication-fraction-worksheet', component: MultiplicationFractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'subtraction-fraction-worksheet', component: SubtractionFractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'division-fraction-worksheet', component: DivisionFractionWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'decimal-worksheet', component: DecimalWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'addition-decimal-worksheet', component: AdditionDecimalWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'multiplication-decimal-worksheet', component: MultiplicationDecimalWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'subtraction-decimal-worksheet', component: SubtractionDecimalWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'division-decimal-worksheet', component: DivisionDecimalWorksheetComponent, canActivate: [StudentAuthGuard] },
	{ path: 'test-list', component: TestListComponent, canActivate: [StudentAuthGuard] },
	{ path: 'test-worksheet', component: TestWorksheetComponent, canActivate: [StudentAuthGuard] },

	{ path: 'teacher-basic-operators', component: BasicOperatorsComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-fraction-operators', component: FractionOperatorsComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-decimal-operators', component: DecimalOperatorsComponent, canActivate: [TeachAuthGuard] },

	{ path: 'teacher-view-test', component: ViewTestComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-operators', component: TeachOperatorComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-test-list', component: TeachTestListComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-student-list', component: TeachStudentListComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-edit-student/:id', component: TeachEditStudentComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-conducted-test', component: TeachConductedTestListComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-test-result', component: TeachTestResultComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-addition-worksheet', component: TeachAdditionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-subtraction-worksheet', component: TeachSubtractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-multiplication-worksheet', component: TeachMultiplicationWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-division-worksheet', component: TeachDivisionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-fraction-worksheet', component: TeachFractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-addition-fraction-worksheet', component: TeachAdditionFractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-subtraction-fraction-worksheet', component: TeachSubtractionFractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-multiplication-fraction-worksheet', component: TeachMultiplicationFractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-division-fraction-worksheet', component: TeachDivisionFractionWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-decimal-worksheet', component: TeachDecimalWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-addition-decimal-worksheet', component: TeachAdditionDecimalWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-subtraction-decimal-worksheet', component: TeachSubtractionDecimalWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-multiplication-decimal-worksheet', component: TeachMultiplicationDecimalWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-division-decimal-worksheet', component: TeachDivisionDecimalWorksheetComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-profile', component: TeachProfileComponent, canActivate: [TeachAuthGuard] },
	{ path: 'teacher-edit-profile/:id', component: TeachEditProfileComponent, canActivate: [TeachAuthGuard] },

	{ path: 'admin-login', component: AdminLoginComponent},
	{ path: 'admin-create-user', component: AdminCreateUserComponent},
	{ path: 'admin-user-list', component: AdminUsersListComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-edit-student/:id', component: AdminEditStudentComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-edit-teacher/:id', component: AdminEditTeacherComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-time-management', component: TimeManagementComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-add-time', component: AddTimeManagementComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-state-management', component: StateManagementComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-edit-time/:id', component: EditTimeManagementComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-edit-state/:id', component: EditStateManagementComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-created-test', component: AdminCreatedTestComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-profile', component: AdminProfileComponent, canActivate: [AdminAuthGuard] },
	{ path: 'admin-edit-profile/:id', component: AdminEditProfileComponent, canActivate: [AdminAuthGuard] },

	{ path: 'guest-join', component: GuestCreateComponent},
	{ path: 'guest-test-list', component: GuestTestListComponent, canActivate: [GuestAuthGuard] },
	{ path: 'guest-test-worksheet', component: GuestTestWorksheetComponent, canActivate: [GuestAuthGuard] },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
