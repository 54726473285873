import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-add-time-management',
	templateUrl: './add-time-management.component.html',
	styleUrls: ['./add-time-management.component.css']
})
export class AddTimeManagementComponent implements OnInit {

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	model: any = {}
	p = 1
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	timeH: number;
	timeM: number;
	timeS: number;

	constructor(public authService: AuthService,
		private fb: FormBuilder,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
	}

	ngOnInit() {
	}

	reset(f: NgForm) {
		f.resetForm();
	}

	onSubmit(form: NgForm) {
		let keepGoing = true;
		if (form.valid == true) {
			this.db.database.ref('time_management')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().timeH;
						const b = data.val().timeM;
						const c = data.val().timeS;
						if (keepGoing) {
							if (a === this.model.timeH && b === this.model.timeM && c === this.model.timeS) {
								this.toastr.error('Time already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.list('/time_management').push({
							timeH: this.model.timeH,
							timeM: this.model.timeM,
							timeS: this.model.timeS,
						});
						this.toastr.success('Time successfully added');
						this.reset(form)
					}
				})
		}
	}
}
