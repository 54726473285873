import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TeachAuthGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.isLoggedInTeacher) {
			return true;
		}
		// localStorage.setItem('isLoggedInStudent', 'false');
		// localStorage.removeItem('tokenStudent');

		localStorage.setItem('isLoggedInTeacher', 'false');
		localStorage.removeItem('currentTeacherToken');

		// localStorage.setItem('isLoggedInAdmin', 'false');
		// localStorage.removeItem('tokenAdmin');
		
		this.router.navigate(['/login']);
		return false;
	}

	get isLoggedInTeacher(): boolean {
		let status = false;
		if (localStorage.getItem('isLoggedInTeacher') == "true") {
			status = true;
		}
		else {
			status = false;
		}
		return status;
	}
}


