import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { Auth } from './service/auth';
import { GuestAuthGuard } from './guest/guards/guest-auth.guard';
import { StudentAuthGuard } from './student/guards/student-auth.guard';
import { TeachAuthGuard } from './teacher/guards/teach-auth.guard';
import { AdminAuthGuard } from './admin/guards/admin-auth.guard';

import { Subscription } from 'rxjs';

export let browserRefresh = false;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'MathQuiz';
	teachersession;

	subscription: Subscription;

	constructor(public authService: AuthService,
		public guestAuth: GuestAuthGuard,
		public studentAuth: StudentAuthGuard,
		public teacherAuth: TeachAuthGuard,
		public adminAuth: AdminAuthGuard,
		private router: Router) {
	  this.subscription = router.events.subscribe((event) => {
		  if (event instanceof NavigationStart) {
			browserRefresh = !router.navigated;
		  }
	  });
	}
  
	ngOnDestroy() {
	  this.subscription.unsubscribe();
	}

	ngOnInit() {
		if(this.guestAuth.isLoggedInGuest) {
			this.logoutGuest();
		}
	}

	logoutGuest() {
		this.authService.signOutGuest();
	}

	logoutStudent() {
		this.authService.signOutStudent();
	}

	logoutTeacher() {
		this.authService.signOutTeacher();
	}

	logoutAdmin() {
		this.authService.signOutAdmin();
	}

}
