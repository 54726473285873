import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-division-decimal-worksheet',
	templateUrl: './teach-division-decimal-worksheet.component.html',
	styleUrls: ['./teach-division-decimal-worksheet.component.css']
})
export class TeachDivisionDecimalWorksheetComponent implements OnInit {

	quizDetailsId: string;
	numberWholeDividend: string;
	numberDecimalDividend: string;
	numberWholeDivisor: string;
	numberDecimalDivisor: string;
	nOfQuestions: string;

	decimalPlaceofAnswer;
	decimalPlacesofNum1;

	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	resultval = [];
	resultval2 = [];
	resultList = [];
	sum;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();


	constructor(private db: AngularFireDatabase, 
		public router: Router,
		public toastr: ToastrService) { }

	ngOnInit() {
		this.sessionQuizDetails();
		this.sessionWholeNumberDividend();
		this.sessionDecimalNumberDividend();
		this.sessionWholeNumberDivisor();
		this.sessionDecimalNumberDivisor();
		this.sessionProblemNumber();
		this.create();
	}

	sessionQuizDetails() {
		this.quizDetailsId = localStorage.getItem('quizDetailsToken');
	}

	sessionWholeNumberDividend() {
		this.numberWholeDividend = localStorage.getItem('numberWholeDividend');
	}

	sessionDecimalNumberDividend() {
		this.numberDecimalDividend = localStorage.getItem('numberDecimalDividend');
	}

	sessionWholeNumberDivisor() {
		this.numberWholeDivisor = localStorage.getItem('numberWholeDivisor');
	}

	sessionDecimalNumberDivisor() {
		this.numberDecimalDivisor = localStorage.getItem('numberDecimalDivisor');
	}

	sessionProblemNumber() {
		this.nOfQuestions = localStorage.getItem('numberQuestion');
	}

	randomIntFromInterval(numberWhole, numberDecimal) {
		let precision = numberDecimal;
		return Math.floor(Math.random() * (numberWhole * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	randomIntFromInterval2(min, rangeDivisor) {
		return Math.floor(Math.random() * (rangeDivisor - min + 1) + min);
	}

	create() {
		window.scroll(0, 0);
		const length = Number(this.nOfQuestions);

		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.list.push(i);
		}
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			const num2 = this.randomIntFromInterval(this.numberWholeDivisor, this.numberDecimalDivisor);
			this.list3.push(num2);

			const num1WithDigits = Number((Number(this.numberWholeDividend) - 1) + '.' + (Number(this.numberDecimalDividend) - 1))

			const answer = num1WithDigits / this.list3[i]

			const answerWhole = Math.floor(answer)
			const answerDecimal = (answer + "").split(".")[1]

			const randomAnswer = this.randomIntFromInterval(answerWhole, answerDecimal);

			const decimalCount = num => {
				const numStr = String(num);
				if (numStr.includes('.')) {
					return numStr.split('.')[1].length;
				};
				return 0;
			}
			this.list5.push(decimalCount(this.list3[i]))
			this.decimalPlacesofNum1 = decimalCount(num1WithDigits)

			if (this.decimalPlacesofNum1 > this.list5[i]) {
				this.decimalPlaceofAnswer = this.decimalPlacesofNum1 - this.list5[i]
			}
			else {
				this.decimalPlaceofAnswer = 0
			}

			const value = Math.pow(10, this.decimalPlaceofAnswer)
			var answerWithDecimalPlaces = parseInt('' + (randomAnswer * value)) / value;

			const num1 = answerWithDecimalPlaces * this.list3[i];
			this.list6.push(num1.toFixed(this.decimalPlacesofNum1))

			this.resultval[i] =  answerWithDecimalPlaces;
		}
	}

	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.db.list('teacher_questions').push({
				quizId: this.quizDetailsId,
				num1: this.list6[i],
				num2: this.list3[i],
				answer: this.resultval[i],
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}
}

