import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-subtraction-worksheet',
	templateUrl: './subtraction-worksheet.component.html',
	styleUrls: ['./subtraction-worksheet.component.css']
})
export class SubtractionWorksheetComponent implements OnInit {

	model: any = { 'theCheckbox': false, 'positiveAnswerbox': false };

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	numberProblem: number;
	questions = [];
	list = [];
	list2 = [];
	list3 = [];
	result = [];
	numberDigits: number;
	currentStudentToken;

	worksheet = false;

	score: number;
	total: number;
	counter;
	seconds;
	allowcatedSeconds;
	interval;
	theCheckbox = false;
	positiveAnswerbox = false;
	color = [];
	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	progress;

	timeStarted = false;
	disappaer = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	timeS;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase) { }

	ngOnInit() {
		this.score = 0;
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenStudentRegister();
		this.getTime();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem("currentStudentToken")
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	randomIntFromInterval(min, numberDigits) {
		return Math.floor(Math.random() * (numberDigits - min + 1) + min);
	}

	continue() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.allowcatedSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		if (this.model.theCheckbox === false) {
			this.interval = setInterval(() => {
				this.seconds--;
				if (this.seconds > 0) {
					this.timeStarted = true;
					if (this.seconds === 0) {
						this.disappaer = true;
					}
				}
			}, 1000)
		} else {
			this.interval = setInterval(() => {
				if (this.seconds > 0) {
					this.timeStarted = true;
					this.seconds--;
					if (this.seconds === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmit();
					}
				}
			}, 1000);
		}
		this.worksheet = true;
		window.scroll(0, 0);
		if (this.model.positiveAnswerbox == true) {
			for (let i = 0; i < this.model.numberProblem; i++) {
				this.list.push(i);
				const j = this.randomIntFromInterval(0, this.model.numberDigits);
				this.list2.push(j);
				const k = Math.floor(Math.random() * ((j - 1) - 0 + 1) + 0)
				this.list3.push(k);
			}
		} else {
			for (let i = 0; i < this.model.numberProblem; i++) {
				this.list.push(i);
				const j = this.randomIntFromInterval(0, this.model.numberDigits);
				this.list2.push(j);
				const k = this.randomIntFromInterval(0, this.model.numberDigits);
				this.list3.push(k);
			}
		}
		this.total = this.model.numberProblem;
	}

	onSubmit() {
		this.submittedAnswers = true;
		window.scroll(0, 0);
		this.pauseTimer();
		for (let i = 0; i < this.model.numberProblem; i++) {
			if (this.result[i] == this.list2[i] - this.list3[i]) {
				this.color[i] = 'green';
				this.score = this.score + 1;
			}
			if (this.result[i] != this.list2[i] - this.list3[i]) {
				this.color[i] = 'red';
			}
			if (this.result[i] == undefined) {
				this.result[i] = this.list2[i] - this.list3[i];
				this.color[i] = 'red';
			}
		}
	}

	onProgress() {
		this.resultSheet = true;
		window.scroll(0, 0);
		this.addDatabase();
	}

	addDatabase() {
		this.progress = (this.score / this.total) * 100;
		this.db.list('student_quiz').push({
			userId: this.currentStudentToken,
			date: this.mm + '-' + this.dd + '-' + this.yyyy,
			operator: 'Subtraction',
			allocatedTime: this.allowcatedSeconds,
			consumeTime: this.allowcatedSeconds - this.seconds,
			totalQuiz: this.total,
			score: this.score,
			precentage: this.progress
		});
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.seconds--;
			if (this.seconds > 0) {
				this.timeStarted = true;
				if (this.seconds === 0) {
					this.disappaer = true;
				}
			}
		}, 1000)
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}

}