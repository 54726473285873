import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GuestAuthGuard implements CanActivate {

	constructor(private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.isLoggedInGuest) {
			return true;
		}
		localStorage.setItem('isLoggedInGuest', 'false');
		localStorage.removeItem('currentGuestToken');
		
		this.router.navigate(['/guest-login']);
		return false;
	}

	get isLoggedInGuest(): boolean {
		let status = false;
		if (localStorage.getItem('isLoggedInGuest') == "true") {
			status = true;
		}
		else {
			status = false;
		}
		return status;
	}
}

