import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

	currentAdminToken;
	RegisterList: Auth[];

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.tokenAdminRegister();
		this.getUserRegister();
	}

	tokenAdminRegister() {
		this.currentAdminToken = localStorage.getItem('currentAdminToken')
	}

	getUserRegister() {
		const s = this.authService.getRegisterAdminList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId;
				if (this.currentAdminToken === b) {
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

}