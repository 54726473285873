import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-time-management',
  templateUrl: './edit-time-management.component.html',
  styleUrls: ['./edit-time-management.component.css']
})
export class EditTimeManagementComponent implements OnInit {

 	submitted = false;
	editTimeForm: FormGroup;

	encryptMode: boolean;
	encryptPswdR: string;
	dencryptPswdL: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		private actRoute: ActivatedRoute,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		const id = this.actRoute.snapshot.paramMap.get('id');
		this.authService.getTime(id).valueChanges().subscribe(data => {
			this.editTimeForm.setValue(data)
		})
		this.timeForm();
	}

	timeForm() {
		this.editTimeForm = this.fb.group({
			timeH: ['', Validators.required],
			timeM: ['', Validators.required],
			timeS: ['', Validators.required],
			userId: ['']
		});
	}

	get e(): any {
		return this.editTimeForm.controls;
	}

	get timeH() {
		return this.editTimeForm.get('timeH');
	}

	get timeM() {
		return this.editTimeForm.get('timeM');
	}

	get timeS() {
		return this.editTimeForm.get('timeS');
	}

	ResetForm() {
		this.editTimeForm.reset();
	}

	onUpdate(): void {
		this.submitted = true;
		let keepGoing = true;
		if (this.editTimeForm.valid == true) {
			this.db.database.ref('time_management')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().timeH;
						const b = data.val().timeM;
						const c = data.val().timeS;
						if (keepGoing) {
							if (a === this.e.timeH.value && b === this.e.timeM.value && c === this.e.timeS.value) {
								this.toastr.error('Time already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.object('/time_management/' + this.actRoute.snapshot.paramMap.get('id')).update({
							timeH: this.e.timeH.value,
							timeM: this.e.timeM.value,
							timeS: this.e.timeS.value,
						});
						this.toastr.success('Time successfully updated');
						this.router.navigate(['admin-time-management']);
					}
				})
		}
	}

}
