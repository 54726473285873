import { Component, OnInit } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-multiplication-worksheet',
	templateUrl: './multiplication-worksheet.component.html',
	styleUrls: ['./multiplication-worksheet.component.css']
})
export class MultiplicationWorksheetComponent implements OnInit {

	model: any = { 'theCheckbox': false };

	TimeList: Auth[];
	searchText;
	orderPipe: string = 'time';
	reverse: boolean = false;

	type = 0;
	submittedOrder = false;
	submittedUnorder = false;

	numberTest1: string;
	numberTest2: string;
	sectionNo: number;
	currentStudentToken;
	number = [];

	list = [];
	list2 = [];
	list3 = [];
	resultval;
	resultList;
	checkVal;

	score: number;
	total: number;
	orderGame = false;
	unorderGame = false;

	counter;
	seconds;
	allowcatedSeconds;
	interval;

	theCheckbox = false;
	answerSheetOrder = false;
	answerSheetUnorder = false;
	color;

	result = [];

	timeStarted = false;
	disappaer = false;

	resultSheet = false;
	progress;
	selectedIndex: number;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(public authService: AuthService,
		private db: AngularFireDatabase) { }

	ngOnInit() {
		this.score = 0;
		for (let i = 1; i <= 20; i++) {
			this.number.push(i);
		}
		this.tokenStudentRegister();
		this.getTime();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem("currentStudentToken")
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	order() {
		this.submittedOrder = !this.submittedOrder;
	}
	unorder() {
		this.submittedUnorder = !this.submittedUnorder;
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	continueOrder() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.allowcatedSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		if (this.model.theCheckbox === false) {
			this.interval = setInterval(() => {
				this.seconds--;
				if (this.seconds > 0) {
					this.timeStarted = true;
					if (this.seconds === 0) {
						this.disappaer = true;
					}
				}
			}, 1000)
		} else {
			this.interval = setInterval(() => {
				if (this.seconds > 0) {
					this.timeStarted = true;
					this.seconds--;
					if (this.seconds === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmitOrder();
					}
				}
			}, 1000);
		}
		this.orderGame = true;
		window.scroll(0, 0);
		for (let c = 0; c < this.model.sectionNo; c++) {
			this.list3.push(c)
		}
		const length = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		this.resultval = new Array(length);
		this.resultList = new Array(length);
		this.color = new Array(length);
		for (let i = 0; i < length; i++) {
			this.resultval[i] = new Array(length);
			this.resultList[i] = new Array(length);
			this.color[i] = new Array(length);
		}
		for (let i = Number(this.model.numberTest1); i <= Number(this.model.numberTest2); i++) {
			this.list.push(('0' + i).slice(-2));
		}
		this.total = length * length * this.model.sectionNo;
		this.select(0);
	}

	continueUnorder() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.allowcatedSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		if (this.model.theCheckbox === false) {
			this.interval = setInterval(() => {
				this.seconds--;
				if (this.seconds > 0) {
					this.timeStarted = true;
					if (this.seconds === 0) {
						this.disappaer = true;
					}
				}
			}, 1000)
		} else {
			this.interval = setInterval(() => {
				if (this.seconds > 0) {
					this.timeStarted = true;
					this.seconds--;
					if (this.seconds === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmitUnorder();
					}
				}
			}, 1000);
		}
		this.unorderGame = true;
		window.scroll(0, 0);
		for (let c = 0; c < this.model.sectionNo; c++) {
			this.list3.push(c);
		}
		const length = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		this.total = length * length * this.model.sectionNo;
		this.resultval = new Array(length);
		this.resultList = new Array(length);
		this.color = new Array(length);
		for (let i = 0; i < length; i++) {
			this.resultval[i] = new Array(length);
			this.resultList[i] = new Array(length);
			this.color[i] = new Array(length);
		}
		for (let i = Number(this.model.numberTest1); i <= Number(this.model.numberTest2); i++) {
			this.list.push(i);
			this.list2.push(i);
		}
		for (let i = this.list.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const x = this.list[i];
			this.list[i] = this.list[j];
			this.list[j] = x;
			const k = Math.floor(Math.random() * (i + 1));
			const y = this.list2[i];
			this.list2[i] = this.list2[k];
			this.list2[k] = y;
		}
		this.select(0);
	}

	onSubmitOrder() {
		this.pauseTimer();
		this.answerSheetOrder = true;
		window.scroll(0, 0);
		const j = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		for (let i = 0; i < j; i++) {
			for (let k = 0; k < j; k++) {
				this.resultList[i][k] = this.list[i] * this.list[k];
				if (this.resultList[i][k] == this.resultval[i][k]) {
					this.color[i][k] = 'green';
				} else {
					this.color[i][k] = 'red';
				}
				if ((this.list[i] * this.list[k]) == this.resultval[i][k]) {
					this.score = this.score + 1;
				}
			}
		}
	}

	onSubmitUnorder() {
		this.pauseTimer();
		this.answerSheetUnorder = true;
		window.scroll(0, 0);
		const j = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		for (let i = 0; i < j; i++) {
			for (let k = 0; k < j; k++) {
				this.resultList[i][k] = this.list2[i] * this.list[k];
				if (this.resultList[i][k] == this.resultval[i][k]) {
					this.color[i][k] = 'green';
				} else {
					this.color[i][k] = 'red';
				}
				if ((this.list2[i] * this.list[k]) == this.resultval[i][k]) {
					this.score = this.score + 1;
				}
			}
		}
	}

	checkAnswerOrder() {
		this.pauseTimer();
		this.answerSheetOrder = true;
		window.scroll(0, 0);
		const j = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		for (let i = 0; i < j; i++) {
			for (let k = 0; k < j; k++) {
				this.resultval[i][k] = '';
				this.resultList[i][k] = this.list[i] * this.list[k];
				if (this.resultList[i][k] == this.resultval[i][k]) {
					this.color[i][k] = 'green';
				} else {
					this.color[i][k] = 'red';
				}
				if ((this.list[i] * this.list[k]) == this.resultval[i][k]) {
					this.score = this.score + 1;
				}
			}
		}
	}

	checkAnswerUnorder() {
		this.pauseTimer();
		this.answerSheetOrder = true;
		window.scroll(0, 0);
		const j = Number(this.model.numberTest2) - Number(this.model.numberTest1) + 1;
		for (let i = 0; i < j; i++) {
			for (let k = 0; k < j; k++) {
				this.resultval[i][k] = '';
				this.resultList[i][k] = this.list[i] * this.list[k];
				if (this.resultList[i][k] == this.resultval[i][k]) {
					this.color[i][k] = 'green';
				} else {
					this.color[i][k] = 'red';
				}
				if ((this.list[i] * this.list[k]) == this.resultval[i][k]) {
					this.score = this.score + 1;
				}
			}
		}
	}

	addDatabase() {
		this.progress = (this.score / this.total) * 100;
		this.db.list('student_quiz').push({
			userId: this.currentStudentToken,
			date: this.mm + '-' + this.dd + '-' + this.yyyy,
			operator: 'Multiplication',
			allocatedTime: this.allowcatedSeconds,
			consumeTime: this.allowcatedSeconds - this.seconds,
			totalQuiz: this.total,
			score: this.score,
			precentage: this.progress
		});
	}

	nextOrder(x) {
		this.checkAnswerOrder();
		if (this.model.theCheckbox == true) {
			this.interval = setInterval(() => {
				if (this.seconds > 0) {
					this.timeStarted = true;
					this.seconds--;
					if (this.seconds === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmitOrder();
					}
				}
			}, 1000);
		} else {
			this.startTimer();
		}
		this.answerSheetOrder = false;
		this.select(x + 1);
		if (x == (this.model.sectionNo - 1)) {
			this.resultSheet = true;
			clearInterval(this.interval);
			this.addDatabase();
		}
		if (this.model.theCheckbox == true) {
			if (this.seconds == 0) {
				this.resultSheet = true;
				clearInterval(this.interval);
				this.addDatabase();
			}
		}
	}

	nextUnorder(x) {
		this.checkAnswerUnorder();
		if (this.model.theCheckbox == true) {
			this.interval = setInterval(() => {
				if (this.seconds > 0) {
					this.timeStarted = true;
					this.seconds--;
					if (this.seconds === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmitUnorder();
					}
				}
			}, 1000);
		} else {
			this.startTimer();
		}
		this.answerSheetUnorder = false;
		this.select(x + 1);
		if (x == (this.model.sectionNo - 1)) {
			this.resultSheet = true;
			clearInterval(this.interval);
			this.addDatabase();
		}
		if (this.model.theCheckbox == true) {
			if (this.seconds == 0) {
				this.resultSheet = true;
				clearInterval(this.interval);
				this.addDatabase();
			}
		}
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.seconds--;
			if (this.seconds > 0) {
				this.timeStarted = true;
				if (this.seconds === 0) {
					this.disappaer = true;
				}
			}
		}, 1000)
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}

}
