import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../service/auth.service';
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	submitted = false;
	loginForm: FormGroup;
	resetPasswordForm: FormGroup;
	resetPassword = false;
	forgetPassword = false;

	encryptMode: boolean;
	encryptPswdL: string;
	dencryptPswdL: string;

	encryptKey1: string;
	encryptKey2: string;
	dencryptKey: string;

	dataString1: string;
	dataString2: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		$(function () {
			$('.form-holder').delegate('input', 'focus', function () {
				$('.form-holder').removeClass('active');
				$(this).parent().addClass('active');
			})
		});
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		this.userLogin();
		this.userResetPassword()
	}

	userLogin() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-z]{2,3}$')]],
			password: ['', [Validators.required, Validators.minLength(6)]]
		});
	}

	userResetPassword() {
		this.resetPasswordForm = this.fb.group({
			email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-z]{2,3}$')]],
		});
	}

	get l(): any {
		return this.loginForm.controls;
	}

	get r(): any {
		return this.resetPasswordForm.controls;
	}

	convertTextL() {
		if (this.encryptMode) {
			this.encryptPswdL = CryptoJS.AES.encrypt(this.loginForm.controls.password.value.trim(),
				this.loginForm.controls.email.value.trim()).toString();
		}
	}

	onLoginEmail(): void {
		this.submitted = true;
		let keepGoing = true;
		if (this.loginForm.valid == true) {
			this.db.database.ref('user/register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().email;
						const b = data.val().password;
						const c = data.val().status;
						this.dencryptPswdL = CryptoJS.AES.decrypt(b.trim(), a.trim()).toString(CryptoJS.enc.Utf8);
						if (keepGoing) {
							if (a === this.l.email.value && this.dencryptPswdL === this.l.password.value && c === 'Activate') {
								// this.authService.loginWithEmail(this.l.email.value, this.l.password.value)
								// 	.then(() => {
								this.convertTextL();
								this.db.list('/user/login').push({
									email: this.l.email.value,
									password: this.encryptPswdL
								});
								localStorage.setItem('isLoggedInStudent', "true");
								localStorage.setItem('tokenStudent', key);
								this.toastr.success(this.l['email'].value + ' successfully login');
								this.router.navigate(['operators']);
								// })
								// .catch(error => {
								// 	this.router.navigate([' ']);
								// });
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.toastr.error('Email or password is invalid');
					}
				});
		} else {
			this.toastr.error('Form is invalid');
		}
	}

	onForgetPassword() {
		this.forgetPassword = true;
	}

	onSendEmail() {
		localStorage.setItem('passwordEmail', this.r.email.value)
		if (this.resetPasswordForm.valid == true) {
			this.db.database.ref('user/register')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const a = data.val().email;
						if (a === this.r.email.value) {
							const key = data.key;
							this.encryptKey1 = CryptoJS.AES.encrypt(key.trim(),
								this.r.email.value.trim()).toString();
							this.dataString1 = this.encryptKey1.replace(/\+/g, 'AB').replace(/\//g, 'CD').replace(/=/g, 'EF');
							this.toastr.success('Check your email');
						}
					})
					this.emailData();
				})
		}  else {
			this.toastr.error('Your not a student');
		}
	}

	emailData() {
		this.resetPassword = true
		let user = {
			email: this.r.email.value,
			keyEncrypt1: this.dataString1,
			emailType: 'login',
			nofUser: '1'
		}
		this.authService.emailUser("http://localhost:3000/sendmail", user).subscribe(
			data => {
				let res: any = data;
			},
		);
	}
}
