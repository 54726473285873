import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-fraction-operators',
	templateUrl: './fraction-operators.component.html',
	styleUrls: ['./fraction-operators.component.css']
})
export class FractionOperatorsComponent implements OnInit {

	model: any = {
		ANumberQuestion: 0,
		SNumberQuestion: 0,
		MNumberQuestion: 0,
		DNumberQuestion: 0,
		positiveAnswerbox: false,
		shuffleQuiz: false
	}
	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	RegisterList: Auth[];
	keyCode: string;
	currentTeacherToken;
	quizName: string;
	teacherName: string;
	operatorType: string;
	operatorName: string;
	mode: string;
	totalNoQuestion: number;
	counter: string;
	seconds;
	questions = [];

	key: string;

	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	result1 = [];
	result2 = [];
	result3 = [];
	result4 = [];

	symbol = [];

	worksheet = false;

	AList = [];
	SList = [];
	MList = [];
	DList = [];


	constructor(private db: AngularFireDatabase,
		public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) { }

	ngOnInit() {
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.tokenTeacherRegister();
		this.getTeacherRegister();
		this.MakeCode();
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	MakeCode() {
		var length = 6;
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@&/#-=';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		this.keyCode = result;
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	randomIntFromInterval(min, digitNumber) {
		return Math.floor(Math.random() * (digitNumber - min + 1) + min);
	}

	lcm_two_numbers(x, y) {
		return Math.abs((x * y) / this.gcd_two_numbers(x, y));
	}

	gcd_two_numbers(x, y) {
		while (y) {
			var t = y;
			y = x % y;
			x = t;
		}
		return x;
	}

	onCreate() {
		window.scroll(0, 0);
		if (this.model.ACheckBox === false) {
			this.model.ADigitNumber = 0;
			this.model.ANumberQuestion = 0;
		} if (this.model.SCheckBox === false) {
			this.model.SDigitNumber = 0;
			this.model.SNumberQuestion = 0;
		} if (this.model.MCheckBox === false) {
			this.model.MDigitNumber = 0;
			this.model.MNumberQuestion = 0;
		} if (this.model.DCheckBox === false) {
			this.model.DDigitNumber = 0;
			this.model.DNumberQuestion = 0;
		}

		var hms = this.model.counter;
		var a = hms.split(':');
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.totalNoQuestion = Number(this.model.ANumberQuestion) + Number(this.model.SNumberQuestion) + Number(this.model.MNumberQuestion) + Number(this.model.DNumberQuestion);

		let keepGoing = true;
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().code;
					if (keepGoing) {
						if (this.keyCode === a) {
							this.MakeCode();
							return keepGoing = false;
						}
					}
				});
				if (keepGoing === true) {
					this.db.list('teacher_quiz_details').push({
						userId: this.currentTeacherToken,
						testName: this.model.quizName,
						operator: 'Fraction-Operator',
						duration: this.seconds,
						totalQuiz: this.totalNoQuestion,
						status: 'Inactive',
						display: 'No',
						code: this.keyCode,
						shuffleQuiz: this.model.shuffleQuiz
					});
					this.makeQuestion();
					this.sessionQuizDetails();
				}
			});
	}

	makeQuestion() {
		this.worksheet = true;
		

		const ALenght = Number(this.model.ANumberQuestion);
		const SLenght = Number(this.model.SNumberQuestion);
		const MLenght = Number(this.model.MNumberQuestion);
		const DLenght = Number(this.model.DNumberQuestion);

		for (let i = 0; i < ALenght; i++) {
			this.AList.push('+')
		}
		for (let i = 0; i < SLenght; i++) {
			this.SList.push('-')
		}
		for (let i = 0; i < MLenght; i++) {
			this.MList.push('*')
		}
		for (let i = 0; i < DLenght; i++) {
			this.DList.push('/')
		}
		this.symbol = this.AList.concat(this.SList).concat(this.MList).concat(this.DList)

		let currentIndex = this.symbol.length, randomIndex;
		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			[this.symbol[currentIndex], this.symbol[randomIndex]] = [
				this.symbol[randomIndex], this.symbol[currentIndex]];
		}
		for (let i = 0; i < this.symbol.length; i++) {
			this.list.push(i);
			if (this.symbol[i] === '+') {
				if (Number(this.model.ADigitNumber) === 12) {
					this.equalToTwelve(this.model.ADigitNumber)
				} else {
					this.overTwelve(this.model.ADigitNumber)
				}
				this.result2[i] = this.lcm_two_numbers(this.list3[i], this.list4[i])
				this.result1[i] = eval((this.list1[i]) * (this.result2[i] / this.list3[i]) + this.symbol[i] + (this.list2[i]) * (this.result2[i] / this.list4[i]))
			} else if (this.symbol[i] === '-') {
				if (this.model.positiveAnswerbox == true) {
					if (Number(this.model.SDigitNumber) === 12) {
						const j = this.randomIntFromInterval(1, this.model.SDigitNumber);
						this.list3.push(j);
						this.list4.push(j);
						const k = Math.floor(Math.random() * ((j - 1) - 0 + 1) + 0)
						this.list1.push(k);
						const m = Math.floor(Math.random() * (k - 0 + 1) + 0)
						this.list2.push(m);
					}
					else {
						let i = 0;
						do {
							const j = this.randomIntFromInterval(1, this.model.SDigitNumber);
							const k = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
							const l = this.randomIntFromInterval(1, this.model.SDigitNumber);
							const m = Math.floor(Math.random() * ((l - 1) - 1 + 1) + 1)

							const num1 = k / j;
							const num2 = m / l;
							if (num1 > num2) {
								this.list3.push(j);
								this.list1.push(k);
								this.list4.push(l);
								this.list2.push(m);
								i++;
							}
							else {
							}
						} while (i < Number(SLenght))
					}
				}
				if (this.model.positiveAnswerbox == false) {
					if (Number(this.model.SDigitNumber) === 12) {
						this.equalToTwelve(this.model.SDigitNumber)
					} else {
						this.overTwelve(this.model.SDigitNumber)
					}
				}
				this.result2[i] = this.lcm_two_numbers(this.list3[i], this.list4[i])
				this.result1[i] = eval((this.list1[i]) * (this.result2[i] / this.list3[i]) + this.symbol[i] + (this.list2[i]) * (this.result2[i] / this.list4[i]))
			} else if (this.symbol[i] === '*') {
				if (Number(this.model.MDigitNumber) === 12) {
					this.equalToTwelve(this.model.MDigitNumber)
				} else {
					this.overTwelve(this.model.MDigitNumber)
				}
				this.result1[i] = this.list1[i] * this.list2[i]
				this.result2[i] = this.list3[i] * this.list4[i]
			}
			else if (this.symbol[i] === '/') {
				if (Number(this.model.DDigitNumber) === 12) {
					this.equalToTwelve(this.model.DDigitNumber)
				} else {
					this.overTwelve(this.model.DDigitNumber)
				}
				this.result1[i] = this.list1[i] * this.list4[i]
				this.result2[i] = this.list3[i] * this.list2[i]
			}
		}

	}

	equalToTwelve(digitNumber) {
		const j = this.randomIntFromInterval(1, digitNumber);
		this.list3.push(j);
		this.list4.push(j);
		const k = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
		this.list1.push(k);
		const m = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
		this.list2.push(m);
	}

	overTwelve(digitNumber) {
		const j = this.randomIntFromInterval(1, digitNumber);
		this.list3.push(j);
		const k = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
		this.list1.push(k);
		const l = this.randomIntFromInterval(1, digitNumber);
		this.list4.push(l);
		const m = Math.floor(Math.random() * ((l - 1) - 1 + 1) + 1)
		this.list2.push(m);
	}


	sessionQuizDetails() {
		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const id = data.val().userId
					if (id === this.currentTeacherToken) {
						this.key = data.val().code;
					}
				});
				localStorage.setItem('quizDetailsToken', this.key)
			});
	}

	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < this.totalNoQuestion; i++) {
			this.db.list('teacher_questions').push({
				quizId: localStorage.getItem('quizDetailsToken'),
				numTop1: this.list1[i],
				numTop2: this.list2[i],
				numDown1: this.list3[i],
				numDown2: this.list4[i],
				answerTop: this.result1[i],
				answerDown: this.result2[i],
				operatorSymbol: this.symbol[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}

}

