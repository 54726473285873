import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teach-navbar',
  templateUrl: './teach-navbar.component.html',
  styleUrls: ['./teach-navbar.component.css']
})
export class TeachNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
