import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from '../service/auth.service'
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPasswordValidator } from "../confirm-password.validator";
import * as firebase from 'firebase/app';

@Component({
	selector: 'app-auth-action',
	templateUrl: './auth-action.component.html',
	styleUrls: ['./auth-action.component.css']
})
export class AuthActionComponent implements OnInit {

	model: any = {};
	resetForm: FormGroup;

	email: string;
	password: string;
	code: string;
	mode: string;

	dataString: string;
	ciphertext1: string;
	dencryptKey1: string;
	cipherUserType: string;
	dencryptUserType: string;
	ciphertext2: string;
	dencryptKey2: string;

	encryptMode: boolean;
	encryptPswdL: string;

	passwordResetExpired = false;
	passwordResetNotExpired = false
	submitted = false;

	verifyEmailExpired = false;
	verifyEmailNotExpired = false;

	isTextFieldType: boolean;

	constructor(private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private afAuth: AngularFireAuth,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
		this.encryptMode = true;
	}

	ngOnInit() {
		this.route.queryParams
			.subscribe((queryParams: Params) => {
				queryParams['email'] === this.route.snapshot.queryParams['email'] ? true : false
			});
		this.code = this.route.snapshot.queryParams['oobCode'];
		this.mode = this.route.snapshot.queryParams['mode'];

		if (this.mode === 'resetPassword') {
			this.afAuth.auth.verifyPasswordResetCode(this.code)
				.then(() => {
					this.passwordResetNotExpired = true;
				})
				.catch(err => {
					this.passwordResetExpired = true;
				})
		}
		if (this.mode === 'verifyEmail') {
			firebase.auth().applyActionCode(this.code)
				.then(() => {
					this.verifyEmailNotExpired = true
				})
				.catch(err => {
					this.verifyEmailExpired = true
				})
		}

		this.email = this.route.snapshot.queryParams['email'];
		this.router.navigate([], {
			queryParams: {
				'email': null,
				'oobCode': null,
				'mode': null,
				'apiKey': null,
				'lang': null
			},
			queryParamsHandling: 'merge'
		})
		this.passwordresetForm();
	}

	passwordresetForm() {
		this.resetForm = this.fb.group(
			{
				password: ['', [Validators.required, Validators.minLength(6)]],
				confirmPassword: ["", Validators.required]
			},
			{
				validator: ConfirmPasswordValidator("password", "confirmPassword")
			}
		);
	}

	togglePasswordFieldType() {
		this.isTextFieldType = !this.isTextFieldType;
	}

	convertTextL() {
		if (this.encryptMode) {
			this.encryptPswdL = CryptoJS.AES.encrypt(this.r.password.value.trim(), this.email.trim()).toString();
		}
	}

	get r(): any {
		return this.resetForm.controls;
	}

	onResetPassword() {
		this.afAuth.auth
			.confirmPasswordReset(this.code, this.r.password.value)
			.then(() => {
			})
			// this.router.navigate(['signin'])

			.catch(err => {
				// const errorMessage = FirebaseErrors.Parse(err.code); // check this helper class at the bottom
			});
		this.afAuth.auth.verifyPasswordResetCode(this.code)
			.then(() => {
			})
			.catch(err => {
			})

		this.convertTextL();
		this.db.database.ref('user_register')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					const key = data.key;
					const a = data.val().email;
					if (a === this.email) {
						const key = data.key;
						this.db.object('user_register/' + key).update({
							password: this.encryptPswdL
						})
						this.submitted = true;
					}
				})
			})
	}

}
