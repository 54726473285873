import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './service/auth.service';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';

import { OperatorsComponent } from './student/operators/operators.component';
import { MultiplicationWorksheetComponent } from './student/multiplication-worksheet/multiplication-worksheet.component';
import { ProfileComponent } from './student/profile/profile.component';
import { PricingComponent } from './student/pricing/pricing.component';
import { EditProfileComponent } from './student/edit-profile/edit-profile.component';
import { SubtractionWorksheetComponent } from './student/subtraction-worksheet/subtraction-worksheet.component';
import { SelfTestResultComponent } from './student/self-test-result/self-test-result.component';
import { TeacherAssignTestResultComponent } from './student/teacher-assign-test-result/teacher-assign-test-result.component';
import { AdditionWorksheetComponent } from './student/addition-worksheet/addition-worksheet.component';
import { DivisionWorksheetComponent } from './student/division-worksheet/division-worksheet.component';
import { FractionWorksheetComponent } from './student/fraction-worksheet/fraction-worksheet.component';
import { MultiplicationFractionWorksheetComponent } from './student/multiplication-fraction-worksheet/multiplication-fraction-worksheet.component';
import { SubtractionFractionWorksheetComponent } from './student/subtraction-fraction-worksheet/subtraction-fraction-worksheet.component';
import { DivisionFractionWorksheetComponent } from './student/division-fraction-worksheet/division-fraction-worksheet.component';
import { AdditionFractionWorksheetComponent } from './student/addition-fraction-worksheet/addition-fraction-worksheet.component';
import { AdditionDecimalWorksheetComponent } from './student/addition-decimal-worksheet/addition-decimal-worksheet.component';
import { SubtractionDecimalWorksheetComponent } from './student/subtraction-decimal-worksheet/subtraction-decimal-worksheet.component';
import { MultiplicationDecimalWorksheetComponent } from './student/multiplication-decimal-worksheet/multiplication-decimal-worksheet.component';
import { DivisionDecimalWorksheetComponent } from './student/division-decimal-worksheet/division-decimal-worksheet.component';
import { DecimalWorksheetComponent } from './student/decimal-worksheet/decimal-worksheet.component';
import { TestListComponent } from './student/test-list/test-list.component';
import { TestWorksheetComponent } from './student/test-worksheet/test-worksheet.component';
import { StudentAuthGuard } from './student/guards/student-auth.guard';

import { TeachAuthGuard } from './teacher/guards/teach-auth.guard';
import { TeachOperatorComponent } from './teacher/teach-operator/teach-operator.component';
import { TeachTestListComponent } from './teacher/teach-test-list/teach-test-list.component';
import { TeachStudentListComponent } from './teacher/teach-student-list/teach-student-list.component';
import { TeachEditStudentComponent } from './teacher/teach-edit-student/teach-edit-student.component';
import { TeachConductedTestListComponent } from './teacher/teach-conducted-test-list/teach-conducted-test-list.component';
import { TeachTestResultComponent } from './teacher/teach-test-result/teach-test-result.component';
import { TeachProfileComponent } from './teacher/teach-profile/teach-profile.component';

import { BasicOperatorsComponent } from './teacher/basic-operators/basic-operators.component';
import { FractionOperatorsComponent } from './teacher/fraction-operators/fraction-operators.component';
import { DecimalOperatorsComponent } from './teacher/decimal-operators/decimal-operators.component';
import { TeachAdditionWorksheetComponent } from './teacher/teach-addition-worksheet/teach-addition-worksheet.component';
import { TeachAdditionFractionWorksheetComponent } from './teacher/teach-addition-fraction-worksheet/teach-addition-fraction-worksheet.component';
import { TeachAdditionDecimalWorksheetComponent } from './teacher/teach-addition-decimal-worksheet/teach-addition-decimal-worksheet.component';
import { TeachDivisionWorksheetComponent } from './teacher/teach-division-worksheet/teach-division-worksheet.component';
import { TeachDivisionFractionWorksheetComponent } from './teacher/teach-division-fraction-worksheet/teach-division-fraction-worksheet.component';
import { TeachDivisionDecimalWorksheetComponent } from './teacher/teach-division-decimal-worksheet/teach-division-decimal-worksheet.component';
import { TeachMultiplicationWorksheetComponent } from './teacher/teach-multiplication-worksheet/teach-multiplication-worksheet.component';
import { TeachMultiplicationFractionWorksheetComponent } from './teacher/teach-multiplication-fraction-worksheet/teach-multiplication-fraction-worksheet.component';
import { TeachMultiplicationDecimalWorksheetComponent } from './teacher/teach-multiplication-decimal-worksheet/teach-multiplication-decimal-worksheet.component';
import { TeachSubtractionWorksheetComponent } from './teacher/teach-subtraction-worksheet/teach-subtraction-worksheet.component';
import { TeachSubtractionFractionWorksheetComponent } from './teacher/teach-subtraction-fraction-worksheet/teach-subtraction-fraction-worksheet.component';
import { TeachSubtractionDecimalWorksheetComponent } from './teacher/teach-subtraction-decimal-worksheet/teach-subtraction-decimal-worksheet.component';
import { TeachFractionWorksheetComponent } from './teacher/teach-fraction-worksheet/teach-fraction-worksheet.component';
import { TeachDecimalWorksheetComponent } from './teacher/teach-decimal-worksheet/teach-decimal-worksheet.component';
import { TeachEditProfileComponent } from './teacher/teach-edit-profile/teach-edit-profile.component';
import { ViewTestComponent } from './teacher/view-test/view-test.component';

import { AdminAuthGuard } from './admin/guards/admin-auth.guard';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminUsersListComponent } from './admin/admin-users-list/admin-users-list.component';
import { AdminEditStudentComponent } from './admin/admin-edit-student/admin-edit-student.component';
import { AdminEditTeacherComponent } from './admin/admin-edit-teacher/admin-edit-teacher.component';
import { TimeManagementComponent } from './admin/time-management/time-management.component';
import { AdminCreatedTestComponent } from './admin/admin-created-test/admin-created-test.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AdminEditProfileComponent } from './admin/admin-edit-profile/admin-edit-profile.component';
import { TeachNavbarComponent } from './teacher/teach-navbar/teach-navbar.component';
import { AdminNavbarComponent } from './admin/admin-navbar/admin-navbar.component';
import { NavbarComponent } from './student/navbar/navbar.component';

import { OnlyNumberDirective } from './only-number.directive';
import { AdminCreateUserComponent } from './admin/admin-create-user/admin-create-user.component';
import { PipeTimeComponent, FormatTimePipe1 } from './pipe-time/pipe-time.component';
import { StateManagementComponent } from './admin/state-management/state-management.component';
import { EditStateManagementComponent } from './admin/edit-state-management/edit-state-management.component';
import { EditTimeManagementComponent } from './admin/edit-time-management/edit-time-management.component';
import { AuthActionComponent } from './auth-action/auth-action.component';
import { AddTimeManagementComponent } from './admin/add-time-management/add-time-management.component';
import { AddStateManagementComponent } from './admin/add-state-management/add-state-management.component';

import { GuestTestListComponent } from './guest/guest-test-list/guest-test-list.component';
import { GuestTestWorksheetComponent } from './guest/guest-test-worksheet/guest-test-worksheet.component';
import { GuestCreateComponent } from './guest/guest-create/guest-create.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		AboutComponent,
		ContactComponent,
		RegisterComponent,
		OperatorsComponent,
		MultiplicationWorksheetComponent,
		FormatTimePipe1,
		ProfileComponent,
		PricingComponent,
		EditProfileComponent,
		SubtractionWorksheetComponent,
		AdditionWorksheetComponent,
		DivisionWorksheetComponent,
		FractionWorksheetComponent,
		MultiplicationFractionWorksheetComponent,
		SubtractionFractionWorksheetComponent,
		DivisionFractionWorksheetComponent,
		AdditionFractionWorksheetComponent,
		AdditionDecimalWorksheetComponent,
		SubtractionDecimalWorksheetComponent,
		MultiplicationDecimalWorksheetComponent,
		DivisionDecimalWorksheetComponent,
		DecimalWorksheetComponent,
		TeachOperatorComponent,
		TeachAdditionWorksheetComponent,
		TeachAdditionFractionWorksheetComponent,
		TeachAdditionDecimalWorksheetComponent,
		TeachProfileComponent,
		TeachTestListComponent,
		TestListComponent,
		TestWorksheetComponent,
		TeachSubtractionWorksheetComponent,
		TeachDivisionDecimalWorksheetComponent,
		TeachDivisionFractionWorksheetComponent,
		TeachDivisionWorksheetComponent,
		TeachMultiplicationDecimalWorksheetComponent,
		TeachMultiplicationFractionWorksheetComponent,
		TeachMultiplicationWorksheetComponent,
		TeachSubtractionDecimalWorksheetComponent,
		TeachSubtractionFractionWorksheetComponent,

		AdminUsersListComponent,
		AdminCreatedTestComponent,
		AdminProfileComponent,
		TeachStudentListComponent,
		TeachFractionWorksheetComponent,
		TeachDecimalWorksheetComponent,
		AdminEditStudentComponent,
		AdminEditTeacherComponent,
		TeachConductedTestListComponent,
		TeachEditStudentComponent,
		TeachTestResultComponent,
		AdminEditProfileComponent,
		TeachEditProfileComponent,
		LoginComponent,
		ForgetPasswordComponent,
		AdminLoginComponent,
		SelfTestResultComponent,
		TeacherAssignTestResultComponent,
		ViewTestComponent,
		BasicOperatorsComponent,
		FractionOperatorsComponent,
		DecimalOperatorsComponent,
		TeachNavbarComponent,
		AdminNavbarComponent,
		NavbarComponent,
		TimeManagementComponent,
		OnlyNumberDirective,
		AdminCreateUserComponent,
		PipeTimeComponent,
		StateManagementComponent,
		EditStateManagementComponent,
		EditTimeManagementComponent,
		AuthActionComponent,
		AddTimeManagementComponent,
		AddStateManagementComponent,
		GuestTestListComponent,
		GuestTestWorksheetComponent,
		GuestCreateComponent,
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NgCircleProgressModule.forRoot({}),
		Ng2SearchPipeModule,
		OrderModule,
		NgxPaginationModule,
		NgxCaptchaModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
		HttpClientModule
	],
	providers: [AuthService, StudentAuthGuard, TeachAuthGuard, AdminAuthGuard],
	bootstrap: [AppComponent]
})
export class AppModule { }
