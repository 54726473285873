import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-self-test-result',
	templateUrl: './self-test-result.component.html',
	styleUrls: ['./self-test-result.component.css']
})
export class SelfTestResultComponent implements OnInit {

	currentStudentToken;
	Result: Auth[];
	RegisterList: Auth[];
	searchText;

	pageRow;
	p = 1;
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.tokenStudentRegister();
		this.worksheetInfo();
		this.getUserRegister();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem('currentStudentToken')
	}

	worksheetInfo() {
		const s = this.authService.getDetails();
		s.snapshotChanges().subscribe(data => {
			this.Result = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId;
				if (this.currentStudentToken === b) {
					this.count += 1;
					this.Result.push(a as Auth);
				}
			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	getUserRegister() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentStudentToken === b) {
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

}