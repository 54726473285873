import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
	selector: 'app-multiplication-decimal-worksheet',
	templateUrl: './multiplication-decimal-worksheet.component.html',
	styleUrls: ['./multiplication-decimal-worksheet.component.css']
})
export class MultiplicationDecimalWorksheetComponent implements OnInit {

	
	numberWhole: string;
	numberDecimal: string;
	numberProblem: string;
	time: string;
	timeNumber;
	timeAllocated: string;
	timeAllocatedNumber;
	timeCheckBox: string;
	currentStudentToken;
	
	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	resultval = [];
	resultval2 = [];
	resultList = [];
	sum;

	score: number;
	total: number;
	interval;
	theCheckbox = false;
	color = [];
	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	progress;

	timeStarted = false;
	disappaer = false;
	worksheet = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();


	constructor(private db: AngularFireDatabase) { }

	ngOnInit() {
		this.score = 0;
		this.sessionWholeNumber();
		this.sessionDecimalNumber();
		this.sessionProblemNumber();
		this.sessionAllocatedTime();
		this.sessionConsumeTime();
		this.sessionTimeCheckBox();
		this.create();
		this.tokenStudentRegister();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem("currentStudentToken")
	}

	sessionWholeNumber() {
		this.numberWhole = localStorage.getItem('numberWhole');
	}

	sessionDecimalNumber() {
		this.numberDecimal = localStorage.getItem('numberDecimal');
	}

	sessionProblemNumber() {
		this.numberProblem = localStorage.getItem('numberProblem');
	}

	sessionAllocatedTime() {
		this.timeAllocated = localStorage.getItem('allocatedT');
		this.timeAllocatedNumber = Number(this.timeAllocated)
	}

	sessionConsumeTime() {
		this.time = localStorage.getItem('consumeT');
		this.timeNumber = Number(this.time)
	}

	sessionTimeCheckBox() {
		this.timeCheckBox = localStorage.getItem('timeCheckBox');
	}

	randomIntFromInterval(numberWhole, numberDecimal) {
		let precision = numberDecimal;
		return Math.floor(Math.random() * (numberWhole * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	create() {
		if (this.theCheckbox === false) {
			this.interval = setInterval(() => {
				this.timeNumber--;
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					if (this.timeNumber === 0) {
						this.disappaer = true;
					}
				}
			}, 1000)
		} else {
			this.interval = setInterval(() => {
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					this.timeNumber--;
					if (this.timeNumber === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmit();
					}
				}
			}, 1000);
		}
		window.scroll(0, 0);
		const length = Number(this.numberProblem);

		for (let i = 0; i < Number(this.numberProblem); i++) {
			this.list.push(i);
			const num1 = this.randomIntFromInterval(this.numberWhole, this.numberDecimal);
			const num2 = this.randomIntFromInterval(this.numberWhole, this.numberDecimal);
			this.list2.push(num1);
			this.list3.push(num2);
			const decimalCount = num => {
				const numStr = String(num);
				if (numStr.includes('.')) {
					return numStr.split('.')[1].length;
				};
				return 0;
			}
			this.list5.push(decimalCount(num1))
			this.list6.push(decimalCount(num2))
		}
		this.total = Number(this.numberProblem);
	}


	onSubmit() {
		this.submittedAnswers = true;
		window.scroll(0, 0);
		this.pauseTimer();
		for (let i = 0; i < Number(this.numberProblem); i++) {
			this.list7[i] = this.list5[i] + this.list6[i];
			this.resultList[i] = this.list2[i] * this.list3[i];
			this.resultval2[i] = this.resultList[i].toFixed(this.list7[i]);

			if (this.resultval2[i] == this.resultval[i]) {
				this.color[i] = 'green';
				this.score = this.score + 1;
			}
			if (this.resultval2[i] != this.resultval[i]) {
				this.color[i] = 'red';
			}
			if (this.resultval[i] == undefined || this.resultval[i] == '') {
				this.resultval[i] = this.resultval2[i]
				this.color[i] = 'red';
			}
		}
	}

	onProgress() {
		this.resultSheet = true;
		window.scroll(0, 0);
		this.addDatabase();
	}

	addDatabase() {
		this.progress = (this.score / this.total) * 100;
		this.db.list('student_quiz').push({
			userId: this.currentStudentToken,
			date: this.mm + '-' + this.dd + '-' + this.yyyy,
			operator: 'Multiplication - Decimal',
			allocatedTime: this.timeAllocatedNumber,
			consumeTime: this.timeAllocatedNumber - this.timeNumber,
			totalQuiz: this.total,
			score: this.score,
			precentage: this.progress
		});
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.timeNumber--;
			if (this.timeNumber > 0) {
				this.timeStarted = true;
				if (this.timeNumber === 0) {
					this.disappaer = true;
				}
			}
		}, 1000)
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}


}
