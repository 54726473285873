import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../service/auth.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from 'angularfire2/auth';
import { Auth } from '../service/auth';
import { StudentAuthGuard } from '../student/guards/student-auth.guard';
import { TeachAuthGuard } from '../teacher/guards/teach-auth.guard';
import { AdminAuthGuard } from '../admin/guards/admin-auth.guard';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	StateList: Auth[];
	order: string = 'state';
	reverse: boolean = false;

	siteKey: string
	submitted = false;
	registerForm: FormGroup;

	encryptMode: boolean;
	encryptPswdR: string;
	dencryptPswdL: string;

	randomCode: string;
	codeString: string;
	encryptEmail: string;
	emailString: string;
	encryptUserType: string;
	userTypeString: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		public db: AngularFireDatabase,
		private afAuth: AngularFireAuth,
		public authService: AuthService,
		public toastr: ToastrService,
		public studentAuth: StudentAuthGuard,
		public teacherAuth: TeachAuthGuard,
		public adminAuth: AdminAuthGuard) {
		this.encryptMode = true;
	}

	ngOnInit(): void {
		if (this.adminAuth.isLoggedInAdmin === true || this.teacherAuth.isLoggedInTeacher === true || this.studentAuth.isLoggedInStudent === true) {
			this.router.navigate(['/home']);
		}
		this.siteKey = '6Ld49YoaAAAAAAq3icZnmWnJyfT-eWsqRjxOzzc9'
		$('.toggle-password').click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		this.userRegister();
		this.getState();
	}

	getState() {
		const s = this.authService.getStateList();
		s.snapshotChanges().subscribe(data => {
			this.StateList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.StateList.push(a as Auth);

			});
		});
	}

	userRegister() {
		this.registerForm = this.fb.group({
			fullName: ['', [Validators.required, Validators.minLength(5)]],
			schoolName: ['', Validators.required],
			bday: ['', Validators.required],
			suburb: ['', Validators.required],
			postalCode: ['', Validators.required],
			state: ['', Validators.required],
			email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-z]{2,3}$')]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			recaptcha: ['', Validators.required]
		});
	}

	get r(): any {
		return this.registerForm.controls;
	}

	convertTextR() {
		if (this.encryptMode) {
			this.encryptPswdR = CryptoJS.AES.encrypt(this.registerForm.controls.password.value.trim(),
				this.registerForm.controls.email.value.trim()).toString();
		}
	}

	ResetForm() {
		this.registerForm.reset();
	}

	onSignUp(): void {
		this.submitted = true;
		this.convertTextR();
		if (this.registerForm.valid == true) {
			this.authService.signUpWithEmail(this.r.email.value, this.r.password.value)
				.then(() => {
					this.db.list('/user_register').push({
						fullName: this.r.fullName.value,
						schoolName: this.r.schoolName.value,
						bday: this.r.bday.value,
						suburb: this.r.suburb.value,
						postalCode: this.r.postalCode.value,
						state: this.r.state.value,
						status: 'Active',
						email: this.r.email.value,
						password: this.encryptPswdR,
						userType: 'Student',
						userId: this.afAuth.auth.currentUser.uid
					});
					this.toastr.success(this.r['email'].value + ' successfully register');
					localStorage.setItem('isLoggedInStudent', "true");
					localStorage.setItem('currentStudentToken', this.afAuth.auth.currentUser.uid);
					this.router.navigate(['operators']);
					this.ResetForm();
				})
				.catch(error => {
					this.toastr.error(error.message);
				});

		}
	}

}
