import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../service/auth.service';
import { Auth } from '../service/auth';
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

	model: any = {};
	email: string;

	RegisterList: Auth[];
	resetPassword = false;

	constructor(public router: Router,
		public authService: AuthService,
		public toastr: ToastrService) {
	}

	ngOnInit() {
		
	}

	onSendEmail() {
		this.authService.resetPassword(this.model.email)
			.then(() => {
				this.resetPassword = true;
				this.toastr.success('Check your email');
				this.getUserRegister();				
			})
			.catch(_error => {
				this.toastr.error(_error)
			});
	}

	getUserRegister() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().email
				const c = item.payload.val().userType
				if (b === this.model.email && c === 'Admin') {
					this.router.navigate(['/admin-login'])
				} 
				if (b === this.model.email && c !== 'Admin') {
					this.router.navigate(['/login'])
				}
			});
		});
	}

}
