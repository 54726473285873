import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-guest-create',
	templateUrl: './guest-create.component.html',
	styleUrls: ['./guest-create.component.css']
})
export class GuestCreateComponent implements OnInit {

	model: any = {};
	g_key: string;

	constructor(public router: Router,
		public db: AngularFireDatabase,
		public authService: AuthService,
		public toastr: ToastrService) {
	}

	ngOnInit() {
	}

	reset(f: NgForm) {
		f.resetForm();
	}

	onLoginEmailGuest(form: NgForm) {
		if (form.valid == true) {
			this.authService.anonymousLogin()
				.then(() => {
					this.db.list('/guest_data').push({
						firstName: this.model.firstName,
						lastName: this.model.lastName,
						email: this.model.email,
					});
					localStorage.setItem('isLoggedInGuest', "true");
					this.toastr.success(this.model.firstName + ' ' + this.model.lastName + ' successfully added');
					this.router.navigate(['guest-test-list']);
					this.reset(form)
				})
		}
	}

}