import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-teach-multiplication-fraction-worksheet',
	templateUrl: './teach-multiplication-fraction-worksheet.component.html',
	styleUrls: ['./teach-multiplication-fraction-worksheet.component.css']
})
export class TeachMultiplicationFractionWorksheetComponent implements OnInit {

	quizDetailsId: string;
	digitNumber: string;
	nOfQuestions: string;

	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	result1 = [];
	result2 = [];

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(private db: AngularFireDatabase, 
		public router: Router,
		public toastr: ToastrService) { }

	ngOnInit() {
		this.sessionQuizDetails();
		this.sessionDigitNumber();
		this.sessionnOfQuestions();
		this.create();
	}

	sessionQuizDetails() {
		this.quizDetailsId = localStorage.getItem('quizDetailsToken');
	}

	sessionDigitNumber() {
		this.digitNumber = localStorage.getItem('range');
	}

	sessionnOfQuestions() {
		this.nOfQuestions = localStorage.getItem('numberQuestion');
	}

	randomIntFromInterval(min, digitNumber) {
		return Math.floor(Math.random() * (digitNumber - min + 1) + min);
	}

	create() {
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.list.push(i);
			if (Number(this.digitNumber) === 12) {
				const j = this.randomIntFromInterval(1, this.digitNumber);
				this.list3.push(j);
				this.list4.push(j);
				const k = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
				this.list1.push(k);
				const m = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
				this.list2.push(m);
			} else {
				const j = this.randomIntFromInterval(1, this.digitNumber);
				this.list3.push(j);
				const k = Math.floor(Math.random() * ((j - 1) - 1 + 1) + 1)
				this.list1.push(k);
				const l = this.randomIntFromInterval(1, this.digitNumber);
				this.list4.push(l);
				const m = Math.floor(Math.random() * ((l - 1) - 1 + 1) + 1)
				this.list2.push(m);
			}
			this.result1[i] = this.list1[i] * this.list2[i]
			this.result2[i] = this.list3[i] * this.list4[i]

		}

	}

	onSubmit() {
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.db.list('teacher_questions').push({
				quizId: this.quizDetailsId,
				numTop1: this.list1[i],
				numTop2: this.list2[i],
				numDown1: this.list3[i],
				numDown2: this.list4[i],
				answerTop: this.result1[i],
				answerDown: this.result2[i]
			});
			this.toastr.success('Successfully Submitted');
			this.router.navigate(['teacher-operators']);
		}
	}


}

