import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
	selector: 'app-pipe-time',
	templateUrl: './pipe-time.component.html',
	styleUrls: ['./pipe-time.component.css']
})
export class PipeTimeComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}

@Pipe({
	name: 'formatTime1'
})
export class FormatTimePipe1 implements PipeTransform {
	transform(value: number): string {
		const hours: number = Math.floor(Math.abs(value) / 3600);
		const minutes: number = Math.floor((Math.abs(value) - hours * 3600) / 60)
		const seconds: number = Math.floor(Math.abs(value) - (hours * 3600 + minutes * 60))
		return (('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2));
	}
}