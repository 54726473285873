import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-test-list',
	templateUrl: './test-list.component.html',
	styleUrls: ['./test-list.component.css']
})
export class TestListComponent implements OnInit {

	currentStudentToken;
	searchText;
	order;
	reverse = false;
	
	studentRegister;
	addtionForm: FormGroup;
	List: Auth[];
	Details: Auth[];
	teacherRegisterList: Auth[];
	Detail: Auth[];

	testDate;
	testID;
	operatorType;

	currentTime;
	timeDuration;
	firstTime;
	startedTime;
	timeGap
	timeGapString;
	endedTime;
	accessTime;
	timeToSubmit;

	p = 1
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	currentDate;

	today = new Date();
	// time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(public authService: AuthService,
		private fb: FormBuilder,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
		setInterval(() => {
			this.currentTime = new Date().toString().split(' ')[4];
		}, 1);
	}

	ngOnInit() {
		this.userLogin();
		this.tokenStudentRegister();
		this.AddedTest();
		this.quizDetails();
		this.getTeacherRegister();
		this.currentDate = this.yyyy + '-' + this.mm + '-' + this.dd
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem('currentStudentToken')
	}

	userLogin() {
		this.addtionForm = this.fb.group({
			code: ['', [Validators.required]]
		});
	}

	get a(): any {
		return this.addtionForm.controls;
	}

	onSubmit() {
		let keepGoing = true;
		if (this.addtionForm.valid == true) {
			this.db.database.ref('student_test')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().userId;
						const b = data.val().test_id;
						if (keepGoing) {
							if (a === this.currentStudentToken && b === this.a.code.value) {
								this.toastr.error('User already added test to your account');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.list('student_test').push({
							userId: this.currentStudentToken,
							test_id: this.a.code.value
						})
						this.toastr.success('Successfully added');
						this.addtionForm.reset();
					}
				})
		} else {
			this.toastr.error('Form Invalid');
		}
	}

	AddedTest() {
		const s = this.authService.getAddedTestList();
		s.snapshotChanges().subscribe(data => {
			this.List = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const sid = item.payload.val().userId;
				this.testID = item.payload.val().test_id;
				if (sid === this.currentStudentToken) {
					this.count += 1;
					this.List.push(a as Auth);
				}
			});
			this.pageLoadRowCount();
		});
	}

	quizDetails() {
		const s = this.authService.getQuizDetails();
		s.snapshotChanges().subscribe(data => {
			this.Details = [];			
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const display = item.payload.val().display;
				if (display === 'Yes') {					
					this.Details.push(a as Auth);
				}
			});
			
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.teacherRegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.teacherRegisterList.push(a as Auth);

			});
		});
	}

	open(code) {
		localStorage.setItem('codeKey', code)

		this.db.database.ref('teacher_quiz_details')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					this.operatorType = data.val().operator;
					const totalQ = data.val().totalQuiz;
					const timeGap = data.val().duration;
					const startedTime = data.val().startedTime;
					const shuffleQuiz = data.val().shuffleQuiz;
					const codeId = data.val().code;
					if (code === codeId) {
						this.testDate = data.val().date;
						localStorage.setItem('operatorType', this.operatorType)
						localStorage.setItem('totalQ', totalQ)
						localStorage.setItem('timeDuration', timeGap)
						localStorage.setItem('firstTime', startedTime)
						localStorage.setItem('shuffleQuiz', shuffleQuiz)
						this.timeCalculation();
					}
				})
			})

		let keepGoing = true;
		this.db.database.ref('student_test')
			.once('value')
			.then((snapshot) => {
				snapshot.forEach(data => {
					this.testID = data.val().test_id;
					const sid = data.val().userId;
					if (keepGoing) {
						if (code === this.testID && sid === this.currentStudentToken) {
							const key = data.key;
							localStorage.setItem('quizKey', key)
							this.db.object('student_test/' + key).update({
								startTime: this.currentTime
							});
							return keepGoing = false;
						}
					}
				});
			})
		// this.router.navigate(['test-worksheet'])
	}

	timeCalculation() {
		this.timeDuration = localStorage.getItem('timeDuration');
		this.firstTime = localStorage.getItem('firstTime')
		this.timeGap = this.timeDuration;

		var dateObj = new Date(this.timeGap * 1000);
		var hours = dateObj.getUTCHours();
		var minutes = dateObj.getUTCMinutes();
		var seconds = dateObj.getSeconds();

		this.timeGapString = hours.toString().padStart(2, '0')
			+ ':' + minutes.toString().padStart(2, '0')
			+ ':' + seconds.toString().padStart(2, '0');


		this.startedTime = this.firstTime;
		const t1 = this.startedTime.split(':');
		const t2 = this.timeGapString.split(':');
		let sec = Number(t1[2]) + Number(t2[2]);
		let mins = Math.floor(sec / 60);
		mins = Number(t1[1]) + Number(t2[1]) + mins;
		let hrs = Math.floor(mins / 60);
		hrs = Number(t1[0]) + Number(t2[0]) + hrs;
		mins = mins % 60;
		sec = sec % 60;
		this.endedTime = hrs + ':' + mins + ':' + sec + ' '

		this.accessTime = this.currentTime
		var val = moment.utc(moment(this.endedTime, "HH:mm:ss").diff(moment(this.accessTime, "HH:mm:ss"))).format("HH:mm:ss")

		var a = val.split(':');
		this.timeToSubmit = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		localStorage.setItem('timeToSubmit', this.timeToSubmit)
		this.butttonDisable();
	}

	butttonDisable() {
		const d1 = new Date(this.testDate + ' ' + this.endedTime);
		const d2 = new Date(this.currentDate + ' ' + this.currentTime);

		if (Date.parse(d1.toString()) >= Date.parse(d2.toString())) {
			this.router.navigate(['test-worksheet'])
		} else {
			this.toastr.error('Test currently not availble');
		}
	}

}