import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import * as $ from 'jquery';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
	selector: 'app-admin-created-test',
	templateUrl: './admin-created-test.component.html',
	styleUrls: ['./admin-created-test.component.css']
})
export class AdminCreatedTestComponent implements OnInit {

	sheet = false;
	
	TestList: Auth[];
	teacherRegisterList: Auth[];
	searchText;
	Infor: Auth[];
	key: string;

	pageRow;
	p = 1;
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;


	constructor(public authService: AuthService,
		private db: AngularFireDatabase) { }

	ngOnInit() {
		$(document).ready(function () {
			var trigger = $('.hamburger'),
				overlay = $('.overlay'),
				isClosed = false;

			trigger.click(function () {
				hamburger_cross();
			});

			function hamburger_cross() {

				if (isClosed == true) {
					overlay.hide();
					trigger.removeClass('is-open');
					trigger.addClass('is-closed');
					isClosed = false;
				} else {
					overlay.show();
					trigger.removeClass('is-closed');
					trigger.addClass('is-open');
					isClosed = true;
				}
			}

			$('[data-toggle="offcanvas"]').click(function () {
				$('#wrapper').toggleClass('toggled');
			});
		});
		this.getTeacherRegister();
		this.quizDetails();
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.teacherRegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.teacherRegisterList.push(a as Auth);

			});
		});
	}

	quizDetails() {
		const s = this.authService.getQuizDetails();
		s.snapshotChanges().subscribe(data => {
			this.TestList = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.count += 1;
				this.TestList.push(a as Auth);
			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	deleteTest($key, code) {
		this.key = $key;
		if (window.confirm('Are sure you want to delete this Test ?')) {
			this.db.object('teacher_quiz_details/' + $key).remove();
			const s = this.authService.getQuestions();
			s.snapshotChanges().subscribe(data => {
				this.Infor = [];
				data.forEach(item => {
					const a = item.payload.toJSON();
					a['$key'] = item.key;
					const b = item.payload.val().quizId
					if (code === b) {
						this.Infor.push(a as Auth);
						this.db.object('teacher_questions/' + a['$key']).remove();
					}
				});
			});
		}
	}

}

