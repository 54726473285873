import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
	selector: 'app-fraction-worksheet',
	templateUrl: './fraction-worksheet.component.html',
	styleUrls: ['./fraction-worksheet.component.css']
})
export class FractionWorksheetComponent implements OnInit {

	model: any = { 'theCheckbox': false, 'positiveCheck': false };

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;

	operatorType;
	mode: string;
	numberProblem: string;
	counter: string;
	seconds;
	allowcatedSeconds;
	questions = [];

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.model.operatorType = 0;
		this.model.mode = 12;
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	continue() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.allowcatedSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		localStorage.setItem('range', this.model.mode);
		localStorage.setItem('numberProblem', this.model.numberProblem);
		localStorage.setItem('consumeT', this.seconds);
		localStorage.setItem('allocatedT', this.allowcatedSeconds);
		const timeCheckBox = this.model.theCheckbox.toString();
		localStorage.setItem('timecheckBox', timeCheckBox);
		const positiveCheckBox = this.model.positiveCheck.toString();
		localStorage.setItem('positiveCheckBox', positiveCheckBox);
	}

}