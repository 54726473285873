import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'

@Component({
	selector: 'app-teach-conducted-test-list',
	templateUrl: './teach-conducted-test-list.component.html',
	styleUrls: ['./teach-conducted-test-list.component.css']
})
export class TeachConductedTestListComponent implements OnInit {

	currentTeacherToken;
	RegisterList: Auth[];
	teacherName: string;
	Details: Auth[];
	Infor: Auth[];
	stateSubmit = false;
	displaySubmit = false;
	key: string;

	p = 1
	arrayOfArrays = [];
	count;
	list1 = [];
	rowCount = 10;
	pageCount;
	min;
	max;
	selectedIndex: number;

	searchText;
	order;
	reverse = false;

	constructor(public authService: AuthService,
		private db: AngularFireDatabase,
		public router: Router) { }

	ngOnInit() {
		$(document).ready(function () {
			var trigger = $('.hamburger'),
				overlay = $('.overlay'),
				isClosed = false;

			trigger.click(function () {
				hamburger_cross();
			});
			function hamburger_cross() {

				if (isClosed == true) {
					overlay.hide();
					trigger.removeClass('is-open');
					trigger.addClass('is-closed');
					isClosed = false;
				} else {
					overlay.show();
					trigger.removeClass('is-closed');
					trigger.addClass('is-open');
					isClosed = true;
				}
			}
			$('[data-toggle="offcanvas"]').click(function () {
				$('#wrapper').toggleClass('toggled');
			});
		});
		this.quizDetails();
		this.tokenTeacherRegister();
		this.getTeacherRegister();
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	quizDetails() {
		const s = this.authService.getQuizDetails();
		s.snapshotChanges().subscribe(data => {
			this.Details = [];
			this.count = 0;
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId;
				if (this.currentTeacherToken === b) {
					this.count += 1;
					this.Details.push(a as Auth);
				}
			});
			this.pageLoadRowCount();
		});
	}

	pageLoadRowCount() {
		this.p = 1;
		this.pageCount = 0;
		this.arrayOfArrays.length = 0;
		for (let i = 1; i <= this.count; i++) {
			this.list1.push(i)
		}
		while (this.list1.length > 0) {
			let arrayElement = this.list1.splice(0, this.rowCount);
			this.arrayOfArrays.push(arrayElement);
		}
		this.pageCount = this.arrayOfArrays.length;
		if (this.p === 1) {
			this.min = 1
			this.max = this.rowCount
			this.select(this.p - 1);
		}
	}

	select(index: number) {
		this.selectedIndex = index;
	}

	displayRowCount(p) {
		this.select(p - 1);
		for (let i = 0; i < this.pageCount; i++) {
			if (i + 1 === p) {
				const x = Math.min(...this.arrayOfArrays[i])
				this.min = x
				const y = Math.max(...this.arrayOfArrays[i])
				this.max = y
			}
		}
	}

	quetionInfor() {
		const s = this.authService.getQuestions();
		s.snapshotChanges().subscribe(data => {
			this.Infor = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().quizId;
				if (this.key === b) {
					this.Infor.push(a as Auth);
					this.db.object('teacher_questions/' + a['$key']).remove();
				}
			});
		});
	}

	viewConductedTest(code, total, testName) {
		localStorage.setItem('quizID', code)
		localStorage.setItem('totalQuiz', total)
		this.router.navigate(['teacher-test-result'])
	}
}
