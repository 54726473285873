import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';

@Component({
  selector: 'app-decimal-worksheet',
  templateUrl: './decimal-worksheet.component.html',
  styleUrls: ['./decimal-worksheet.component.css']
})
export class DecimalWorksheetComponent implements OnInit {

	model: any = { 'theCheckbox': false, 'positiveCheck': false};

	TimeList: Auth[];
	searchText;
	order: string = 'time';
	reverse: boolean = false;
	
	operatorType;
	numberWhole: string;
	numberDecimal: string;
	numberWholeDividend: string;
	numberDecimalDividend: string;
	numberWholeDivisor: string;
	numberDecimalDivisor: string;
	numberAddend: string;
	numberProblem: string;
	questions = [];
	counter;
	seconds;
	allowcatedSeconds;
	theCheckbox = false;
	positiveCheck = false;

	constructor(public authService: AuthService) { }

	ngOnInit() {
		this.model.operatorType = 0;
		for (let i = 5; i <= 100; i += 5) {
			this.questions.push(i);
		}
		this.getTime();
	}

	getTime() {
		const s = this.authService.getTimeList();
		s.snapshotChanges().subscribe(data => {
			this.TimeList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.TimeList.push(a as Auth);

			});
		});
	}

	continue() {
		var hms = this.model.counter;
		var a = hms.split(':');
		this.allowcatedSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		this.seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

		localStorage.setItem('numberWhole', this.model.numberWhole);
		localStorage.setItem('numberDecimal', this.model.numberDecimal);
		localStorage.setItem('numberWholeDividend', this.model.numberWholeDividend);
		localStorage.setItem('numberDecimalDividend', this.model.numberDecimalDividend);
		localStorage.setItem('numberWholeDivisor', this.model.numberWholeDivisor);
		localStorage.setItem('numberDecimalDivisor', this.model.numberDecimalDivisor);
		localStorage.setItem('numberAddend', this.model.numberAddend);
		localStorage.setItem('numberProblem', this.model.numberProblem);
		localStorage.setItem('consumeT', this.seconds);
		localStorage.setItem('allocatedT', this.allowcatedSeconds);
		const timeCheckBox = this.model.theCheckbox.toString();
		localStorage.setItem('timeCheckBox', timeCheckBox);
		const positiveCheckBox = this.model.positiveCheck.toString();
		localStorage.setItem('positiveCheckBox', positiveCheckBox);
	}

}
