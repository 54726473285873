import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
	selector: 'app-addition-decimal-worksheet',
	templateUrl: './addition-decimal-worksheet.component.html',
	styleUrls: ['./addition-decimal-worksheet.component.css']
})
export class AdditionDecimalWorksheetComponent implements OnInit {

	numberWhole: string;
	numberDecimal: string;
	numberAddend: string;
	numberProblem: string;
	time: string;
	timeNumber;
	timeAllocated: string;
	timeAllocatedNumber;
	timeCheckBox: string;
	currentStudentToken;
	score: number;
	total: number;

	list = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];

	resultval;
	resultList;
	decimalPoints;

	interval;
	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	timeStarted = false;
	disappaer = false;

	color = [];
	progress;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(private db: AngularFireDatabase) { }

	ngOnInit() {
		this.score = 0;
		this.sessionWholeNumber();
		this.sessionDecimalNumber();
		this.sessionAddendNumber();
		this.sessionProblemNumber();
		this.sessionAllocatedTime();
		this.sessionConsumeTime();
		this.sessionTimeCheckBox();
		this.create();
		this.tokenStudentRegister();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem("currentStudentToken")
	}

	sessionWholeNumber() {
		this.numberWhole = localStorage.getItem('numberWhole');
	}

	sessionDecimalNumber() {
		this.numberDecimal = localStorage.getItem('numberDecimal');
	}

	sessionAddendNumber() {
		this.numberAddend = localStorage.getItem('numberAddend');
	}

	sessionProblemNumber() {
		this.numberProblem = localStorage.getItem('numberProblem');
	}

	sessionAllocatedTime() {
		this.timeAllocated = localStorage.getItem('allocatedT');
		this.timeAllocatedNumber = Number(this.timeAllocated)
	}

	sessionConsumeTime() {
		this.time = localStorage.getItem('consumeT');
		this.timeNumber = Number(this.time)
	}

	sessionTimeCheckBox() {
		this.timeCheckBox = localStorage.getItem('timeCheckBox');
	}

	randomIntFromInterval(digitsNo1, digitsNo2) {
		let precision = digitsNo2;
		return Math.floor(Math.random() * (digitsNo1 * precision - 1 * precision) + 1 * precision) / (1 * precision);
	}

	create() {
		if (this.timeCheckBox === 'false') {
			this.interval = setInterval(() => {
				this.timeNumber--;
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					if (this.timeNumber === 0) {
						this.disappaer = true;
					}
				}
			}, 1000)
		} else {
			this.interval = setInterval(() => {
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					this.timeNumber--;
					if (this.timeNumber === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmit();
					}
				}
			}, 1000);
		}
		window.scroll(0, 0);
		const length = Number(this.numberProblem);

		this.resultval = new Array(length);
		this.resultList = new Array(length);
		this.decimalPoints = new Array(length);

		for (let i = 0; i < length; i++) {
			this.resultval[i] = new Array(length);
			this.resultList[i] = new Array(length);
			this.decimalPoints[i] = new Array(length);
		}
		for (let i = 0; i < Number(this.numberProblem); i++) {
			this.list.push(i);
		}
		for (let i = 0; i < Number(this.numberAddend); i++) {
			this.list2.push(i);
		}
		for (let i = 0; i < 1; i++) {
			this.list3.push(i);
		}
		for (let i = 0; i < Number(this.numberProblem); i++) {
			for (let j = 0; j < Number(this.numberAddend); j++) {
				const num1 = this.randomIntFromInterval(this.numberWhole, this.numberDecimal);
				this.resultval[i][j] = num1;
				const decimalCount = num => {
					const numStr = String(num);
					if (numStr.includes('.')) {
						return numStr.split('.')[1].length;
					};
					return 0;
				}
				this.decimalPoints[i][j] = decimalCount(num1)
			}
		}
		const array = new Array(length).fill(0);
		var largestNumber = array;
		for (let i = 0; i < Number(this.numberProblem); i++) {
			for (let j = 0; j < Number(this.numberAddend); j++) {
				if (this.decimalPoints[i][j] > largestNumber[i]) {
					largestNumber[i] = this.decimalPoints[i][j];
				}
			}
		}
		this.list4 = largestNumber;
		this.total = Number(this.numberProblem);
	}

	onSubmit() {
		this.submittedAnswers = true;
		window.scroll(0, 0);
		this.pauseTimer();
		for (let i = 0; i < Number(this.numberProblem); i++) {
			let arrSum = 0;
			for (let k = 0; k < 1; k++) {
				this.resultval[i].forEach(element => {
					arrSum = arrSum + element;
				});
				this.list5[i] = parseFloat(arrSum.toFixed(this.list4[i]))
				if (this.list5[i] == this.resultList[i][k]) {
					this.color[i] = 'green';
					this.score = this.score + 1;
				}
				if (this.resultList[i][k] != this.list5[i]) {
					this.color[i] = 'red';
				}
				if (this.resultList[i][k] == undefined || this.resultList[i][k] == '') {
					this.resultList[i][k] = this.list5[i];
					this.color[i] = 'red';
				}
			}
		}
	}

	onProgress() {
		this.resultSheet = true;
		window.scroll(0, 0);
		this.addDatabase();
	}

	addDatabase() {
		this.progress = (this.score / this.total) * 100;
		this.db.list('student_quiz').push({
			userId: this.currentStudentToken,
			date: this.mm + '-' + this.dd + '-' + this.yyyy,
			operator: 'Addition - Decimal',
			allocatedTime: this.timeAllocatedNumber,
			consumeTime: this.timeAllocatedNumber - this.timeNumber,
			totalQuiz: this.total,
			score: this.score,
			precentage: this.progress
		});
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.timeNumber--;
			if (this.timeNumber > 0) {
				this.timeStarted = true;
				if (this.timeNumber === 0) {
					this.disappaer = true;
				}
			}
		}, 1000)
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}

}
