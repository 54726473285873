import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
	selector: 'app-division-fraction-worksheet',
	templateUrl: './division-fraction-worksheet.component.html',
	styleUrls: ['./division-fraction-worksheet.component.css']
})
export class DivisionFractionWorksheetComponent implements OnInit {

	digitNumber: string;
	nOfQuestions: string;
	time: string;
	timeNumber;
	timeAllocated: string;
	timeAllocatedNumber;
	timeCheckBox: string;
	currentStudentToken;
	list = [];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	result1 = [];
	result2 = [];
	result3 = [];
	result4 = [];

	score: number;
	total: number;
	interval;

	positiveAnswerbox = false;
	color = [];
	submittedAnswers = false;
	answerSheet = false;
	resultSheet = false;
	progress;

	timeStarted = false;
	disappaer = false;

	today = new Date();
	dd = String(this.today.getDate()).padStart(2, '0');
	mm = String(this.today.getMonth() + 1).padStart(2, '0');
	yyyy = this.today.getFullYear();

	constructor(private db: AngularFireDatabase) { }

	ngOnInit() {
		this.score = 0;
		this.sessionDigitNumber();
		this.sessionnOfQuestions();
		this.sessionAllocatedTime();
		this.sessionConsumeTime();
		this.sessionTimeCheckBox();
		this.create();
		this.tokenStudentRegister();
	}

	tokenStudentRegister() {
		this.currentStudentToken = localStorage.getItem("currentStudentToken")
	}

	sessionDigitNumber() {
		this.digitNumber = localStorage.getItem('range');
	}

	sessionnOfQuestions() {
		this.nOfQuestions = localStorage.getItem('numberProblem');
	}

	sessionAllocatedTime() {
		this.timeAllocated = localStorage.getItem('allocatedT');
		this.timeAllocatedNumber = Number(this.timeAllocated)
	}

	sessionConsumeTime() {
		this.time = localStorage.getItem('consumeT');
		this.timeNumber = Number(this.time)
	}

	sessionTimeCheckBox() {
		this.timeCheckBox = localStorage.getItem('timecheckBox');
	}

	randomIntFromInterval(min, digitNumber) {
		return Math.floor(Math.random() * (digitNumber - min + 1) + min);
	}

	create() {
		if (this.timeCheckBox === 'false') {
			this.interval = setInterval(() => {
				this.timeNumber--;
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					if (this.timeNumber === 0) {
						this.disappaer = true;
					}
				}
		   },1000)
		} else {
			this.interval = setInterval(() => {
				if (this.timeNumber > 0) {
					this.timeStarted = true;
					this.timeNumber--;
					if (this.timeNumber === 0) {
						this.disappaer = true;
						clearInterval(this.interval);
						this.onSubmit();
					}
				}
			}, 1000);
		}
		window.scroll(0, 0);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.list.push(i);
			if(Number(this.digitNumber) === 12) {
				const j = this.randomIntFromInterval(1, this.digitNumber);
				this.list3.push(j);
				this.list4.push(j);
				const k = Math.floor(Math.random() * ((j -1) - 1 + 1) + 1)
				this.list1.push(k);
				const m = Math.floor(Math.random() * ((j -1) - 1 + 1) + 1)
				this.list2.push(m);
			} else {
				const j = this.randomIntFromInterval(1, this.digitNumber);
				this.list3.push(j);
				const k = Math.floor(Math.random() * ((j -1) - 1 + 1) + 1)
				this.list1.push(k);
				const l = this.randomIntFromInterval(1, this.digitNumber);
				this.list4.push(l);
				const m = Math.floor(Math.random() * ((l -1) - 1 + 1) + 1)
				this.list2.push(m);
			}
		}
		this.total = Number(this.nOfQuestions);
	}

	onSubmit() {
		this.submittedAnswers = true;
		window.scroll(0, 0);
		clearInterval(this.interval);
		for (let i = 0; i < Number(this.nOfQuestions); i++) {
			this.result3[i] = this.list1[i] * this.list4[i]
			this.result4[i] = this.list3[i] * this.list2[i]
			if(this.result3[i] == this.result1[i] && this.result4[i] == this.result2[i]) {
				this.color[i] = 'green';
				this.score = this.score + 1;
			}
			if (this.result3[i] != this.result1[i] || this.result4[i] != this.result2[i]) {
				this.color[i] = 'red';
			}
			if (this.result1[i] == undefined || this.result2[i] == undefined || this.result1[i] == '' || this.result2[i] == '') {
				this.result1[i] = this.result3[i]
				this.result2[i] = this.result4[i]
				this.color[i] = 'red';
			}
		}
	}

	onProgress() {
		this.resultSheet = true;
		window.scroll(0, 0);
		this.addDatabase();
	}

	addDatabase() {
		this.progress = (this.score / this.total) * 100;
		this.db.list('student_quiz').push({
			userId: this.currentStudentToken,
			date: this.mm + '-' + this.dd + '-' + this.yyyy,
			operator: 'Division - Fraction',
			allocatedTime: this.timeAllocatedNumber,
			consumeTime: this.timeAllocatedNumber - this.timeNumber,
			totalQuiz: this.total,
			score: this.score,
			precentage: this.progress
		});
	}

	startTimer() {
		this.interval = setInterval(() => {
			this.timeNumber--;
			if (this.timeNumber > 0) {
				this.timeStarted = true;
				if (this.timeNumber === 0) {
					this.disappaer = true;
				}
			}
	   },1000)
	}

	pauseTimer() {
		this.timeStarted = false;
		clearInterval(this.interval);
	}

}
