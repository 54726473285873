import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from '../../service/auth.service';
import { CsvService } from '../../service/csv.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-teach-test-result',
	templateUrl: './teach-test-result.component.html',
	styleUrls: ['./teach-test-result.component.css']
})
export class TeachTestResultComponent implements OnInit {

	timeDuration;
	firstTime;
	startedTime;
	timeGap
	timeGapString;
	endedTime;

	currentTeacherToken;
	QuizCode;
	TotalQuiz;
	TestName;
	TestDate;
	Answer;
	RegisterList: Auth[];

	teacherName: string;
	stateSubmit = false;
	displaySubmit = false;
	key: string;

	order;
	reverse = false;

	Quiz: Auth[];
	Register: Auth[];
	Infor: Auth[];
	Result: Auth[];
	Reservation = [];
	Details: Auth[];
	List: Auth[];
	list1 = [];
	list2 = [];
	list3 = [];
	list4 = [];
	list5 = [];
	list6 = [];
	list7 = [];
	list8 = [];
	list9 = [];
	list10 = [];
	list11 = [];

	name = [];

	combination;
	resultList;
	resultName;
	resultStartTime;
	resultEndTime;
	resultProgress;
	jsonObj;

	text1;
	text2;

	arrayOfArrays = [];

	totalQuiz;
	row;
	column;
	count;

	color;

	constructor(public db: AngularFireDatabase,
		public csvService: CsvService,
		public authService: AuthService,
		public router: Router) {
	}

	ngOnInit(): void {
		$(document).ready(function () {
			var trigger = $('.hamburger'),
				overlay = $('.overlay'),
				isClosed = false;

			trigger.click(function () {
				hamburger_cross();
			});
			function hamburger_cross() {

				if (isClosed == true) {
					overlay.hide();
					trigger.removeClass('is-open');
					trigger.addClass('is-closed');
					isClosed = false;
				} else {
					overlay.show();
					trigger.removeClass('is-closed');
					trigger.addClass('is-open');
					isClosed = true;
				}
			}
			$('[data-toggle="offcanvas"]').click(function () {
				$('#wrapper').toggleClass('toggled');
			});
		});

		this.getTeacherRegister();
		this.tokenTeacherRegister();
		this.tokenQuizCode();
		this.tokenTotalQuiz();
		this.questionList();
		this.ReservationId();
		this.ResultStudentId();
		this.quizDetails();
	}

	getTeacherRegister() {
		const s = this.authService.getRegisterTeacherList();
		s.snapshotChanges().subscribe(data => {
			this.RegisterList = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().userId
				if (this.currentTeacherToken === b) {
					this.teacherName = item.payload.val().fullName;
					this.RegisterList.push(a as Auth);
				}
			});
		});
	}

	tokenTeacherRegister() {
		this.currentTeacherToken = localStorage.getItem('currentTeacherToken')
	}

	tokenQuizCode() {
		this.QuizCode = localStorage.getItem('quizID')
	}

	tokenTotalQuiz() {
		this.totalQuiz = localStorage.getItem('totalQuiz')
	}

	questionList() {
		const s = this.authService.getQuestions();
		s.snapshotChanges().subscribe(data => {
			this.Infor = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().quizId
				if (this.QuizCode === b) {
					this.Infor.push(a as Auth);
				}
			});
			for (let temp of this.Infor) {
				this.list3.push(temp.$key)
			}
		});
	}

	ReservationId() {
		const s = this.authService.getTestResult();
		var total = 0;
		s.snapshotChanges().subscribe(data => {
			this.Result = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.Result.push(a as Auth);
			});
			for (let id of this.Result) {
				for (let temp of this.Infor) {
					if (temp.$key === id.quest_id) {
						this.list2.push(id.reservation_id)
					}
				}
			}
			const arrayWithDuplicates = this.list2;
			this.list5 = arrayWithDuplicates.filter((n, i) => arrayWithDuplicates.indexOf(n) === i);
			for (let duplicate of this.list5) {
				total += 1
			}
			this.row = total
		});
	}

	ResultStudentId() {
		const s = this.authService.getAddedTestList();
		s.snapshotChanges().subscribe(data => {
			this.Reservation = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.Reservation.push(a as Auth);
			});
			for (let temp of this.list5) {
				for (let reservation of this.Reservation) {
					if (temp === reservation.$key) {
						this.list6.push(reservation.userId)
						this.list9.push(reservation.startTime)
						this.list10.push(reservation.endTime)
						this.list11.push(reservation.progress)
					}
				}
			}
			this.registerStudent();
		});
	}

	registerStudent() {
		const s = this.authService.getRegisterStudentList();
		s.snapshotChanges().subscribe(data => {
			this.Register = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				this.Register.push(a as Auth);
			});
			for (let temp of this.list6) {
				for (let register of this.Register) {
					if (register.userId === temp) {
						this.list1.push(register.email)
						this.list8.push(register.$key)
					}
				}
			}
			for (let temp of this.list5) {
				for (let infor of this.Infor) {
					for (let id of this.Result) {
						if (temp === id.reservation_id) {
							if (infor.$key === id.quest_id) {
								this.list7.push(id.answer)
							}
						}
					}
				}

			}
			this.submit();
		});
	}

	submit() {
		this.column = Number(this.totalQuiz) + 4;
		for (let k = 0; k < this.column; k++) {
			this.name[0] = 'Email',
				this.name[1] = 'StartTime',
				this.name[2] = 'EndTime',
				this.name[3] = 'Progress',
				this.name[k] = "Q" + [k - 3];
		}
		this.resultList = new Array(this.totalQuiz);
		this.resultName = new Array(1);
		this.resultStartTime = new Array(1);
		this.resultEndTime = new Array(1);
		this.resultProgress = new Array(1);
		this.text1 = new Array(2);
		this.text2 = new Array(3);
		this.combination = new Array(this.column);
		this.jsonObj = new Array(this.column);
		this.color = new Array(this.column);

		for (let i = 0; i < this.row; i++) {
			this.resultList[i] = new Array(this.totalQuiz);
			this.resultName[i] = new Array(1);
			this.resultStartTime[i] = new Array(1);
			this.resultEndTime[i] = new Array(1);
			this.resultProgress[i] = new Array(1);
			this.text1[i] = new Array(2);
			this.text2[i] = new Array(3);
			this.combination[i] = new Array(this.column);
			this.jsonObj[i] = new Array(this.column);
			this.color[i] = new Array(this.column);
		}

		while (this.list7.length > 0) {
			let arrayElement = this.list7.splice(0, this.totalQuiz);
			this.arrayOfArrays.push(arrayElement);
		}
		for (let i = 0; i < this.row; i++) {
			for (let k = 0; k < this.totalQuiz; k++) {
				this.resultName[i][0] = this.list1[i],
					this.resultStartTime[i][0] = this.list9[i],
					this.resultEndTime[i][0] = this.list10[i],
					this.resultProgress[i][0] = (this.list11[i]).toFixed(0) + '%',
					this.resultList[i][k] = this.arrayOfArrays[i][k];
			}
		}

		for (let i = 0; i < this.row; i++) {
			// this.text1[i] = this.resultName[i].concat(this.resultStartTime[i])
			// this.text2[i] = this.text1[i].concat(this.resultEndTime[i])
			// this.combination[i] = this.text2[i].concat(this.resultList[i])
			this.combination[i] = this.resultName[i].concat(this.resultStartTime[i], this.resultEndTime[i], this.resultProgress[i], this.resultList[i])
		}
		for (let i = 0; i < this.row; i++) {
			this.jsonObj[i] = {}
		}
		for (let i = 0; i < this.row; i++) {
			for (let k = 4; k < this.column; k++) {
				var host1 = "Email";
				var host2 = "StartTime";
				var host3 = "EndTime";
				var host4 = "Progress";
				var newUser = "Q" + [k - 3];
				this.jsonObj[i][host1] = this.combination[i][0];
				this.jsonObj[i][host2] = this.combination[i][1];
				this.jsonObj[i][host3] = this.combination[i][2];
				this.jsonObj[i][host4] = this.combination[i][3];
				this.jsonObj[i][newUser] = this.combination[i][k];
				this.color[i][0] = '400px';
				this.color[i][1] = '100px';
				this.color[i][2] = '100px';
				this.color[i][3] = '100px';
				this.color[i][k] = '50px';
			}
		}
	}

	quizDetails() {
		const s = this.authService.getQuizDetails();
		s.snapshotChanges().subscribe(data => {
			this.Quiz = [];
			data.forEach(item => {
				const a = item.payload.toJSON();
				a['$key'] = item.key;
				const b = item.payload.val().code;
				if (this.QuizCode === b) {
					this.TestName = item.payload.val().testName;
					this.TestDate = item.payload.val().date
					this.timeDuration = item.payload.val().duration;
					this.firstTime = item.payload.val().startedTime
					this.Quiz.push(a as Auth);
				}
			});
			this.timeCalculation();
		});
	}
	
	timeCalculation() {
		this.timeGap = this.timeDuration;
		var dateObj = new Date(this.timeGap * 1000);
		var hours = dateObj.getUTCHours();
		var minutes = dateObj.getUTCMinutes();
		var seconds = dateObj.getSeconds();
		this.timeGapString = hours.toString().padStart(2, '0')
			+ ':' + minutes.toString().padStart(2, '0')
			+ ':' + seconds.toString().padStart(2, '0');
	
		this.startedTime = this.firstTime;
		const t1 = this.startedTime.split(':');
		const t2 = this.timeGapString.split(':');
		let sec = Number(t1[2]) + Number(t2[2]);
		let mins = Math.floor(sec / 60);
		mins = Number(t1[1]) + Number(t2[1]) + mins;
		let hrs = Math.floor(mins / 60);
		hrs = Number(t1[0]) + Number(t2[0]) + hrs;
		mins = mins % 60;
		sec = sec % 60;
		this.endedTime = hrs + ':' + mins + ':' + sec + ' '
		this.TestName = this.TestDate+ " " +this.startedTime+ " - " + this.endedTime + " " +this.TestName
	}
	
	exportToCsv(): void {
		this.csvService.exportToCsv(this.jsonObj, this.TestName, this.name);
	}
}