import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Auth } from '../../service/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router'
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-add-state-management',
	templateUrl: './add-state-management.component.html',
	styleUrls: ['./add-state-management.component.css']
})
export class AddStateManagementComponent implements OnInit {

	model: any = {}

	// currentAdminToken;

	constructor(public authService: AuthService,
		private fb: FormBuilder,
		private db: AngularFireDatabase,
		public router: Router,
		public toastr: ToastrService) {
	}

	ngOnInit() {
		// this.tokenAdminRegister();
	}

	// tokenAdminRegister() {
	// 	this.currentAdminToken = localStorage.getItem('currentAdminToken')
	// }

	reset(f: NgForm) {
		f.resetForm();
	}

	onSubmit(form: NgForm) {
		let keepGoing = true;
		if (form.valid == true) {
			this.db.database.ref('state_management')
				.once('value')
				.then((snapshot) => {
					snapshot.forEach(data => {
						const key = data.key;
						const a = data.val().state;
						if (keepGoing) {
							if (a === this.model.state) {
								this.toastr.error('State already exist');
								return keepGoing = false;
							}
						}
					});
					if (keepGoing === true) {
						this.db.list('/state_management').push({
							state: this.model.state,
							// userId: this.currentAdminToken
						});
						this.toastr.success(this.model.state + ' successfully added');
						this.reset(form)
					}
				})
		}
	}

}

