import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import * as FileSaver from 'file-saver';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';


@Injectable({
	providedIn: 'root'
})
export class CsvService {

	worksheetRef: AngularFireList<any>;

	constructor(private db: AngularFireDatabase) { }

	getDetails() {
		this.worksheetRef = this.db.list('user/test');
		return this.worksheetRef;
	}
	/**
	   * Saves the file on client's machine via FileSaver library.
	   *
	   * @param buffer The data that need to be saved.
	   * @param fileName File name to save as.
	   * @param fileType File type to save as.
	   */
	private saveAsFile(buffer: any, fileName: string, fileType: string): void {
		const data: Blob = new Blob([buffer], { type: fileType });
		FileSaver.saveAs(data, fileName);
	}

	/**
	 * Creates an array of data to csv. It will automatically generate title row based on object keys.
	 *
	 * @param rows array of data to be converted to CSV.
	 * @param fileName filename to save as.
	 * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
	 */
	public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
		if (!rows || !rows.length) {
			return;
		}
		const separator = ',';
		const keys = Object.keys(rows[0]).filter(k => {
			if (columns) {
				return columns.includes(k);
			} else {
				return true;
			}
		});
		const csvContent =
			keys.join(separator) +
			'\n' +
			rows.map(row => {
				return keys.map(k => {
					let cell = row[k] === null || row[k] === undefined ? '' : row[k];
					cell = cell instanceof Date
						? cell.toLocaleString()
						: cell.toString().replace(/"/g, '""');
					if (cell.search(/("|,|\n)/g) >= 0) {
						cell = `"${cell}"`;
					}
					return cell;
				}).join(separator);
			}).join('\n');
		this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
	}
}
