import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../service/auth.service';
import { Auth } from '../service/auth';
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase';
import * as firebase from 'firebase/app';
import { StudentAuthGuard } from '../student/guards/student-auth.guard';
import { TeachAuthGuard } from '../teacher/guards/teach-auth.guard';
import { AdminAuthGuard } from '../admin/guards/admin-auth.guard';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	StudentRegisterList: Auth[];
	model: any = {};
	submitted = false;
	loginForm: FormGroup;
	resetPassword = false;

	encryptMode: boolean;
	encryptPswdL: string;
	decryptPswdL: string;

	email: string;
	password: string;
	userType: string;

	constructor(private fb: FormBuilder,
		public router: Router,
		public db: AngularFireDatabase,
		private afAuth: AngularFireAuth,
		public authService: AuthService,
		public toastr: ToastrService,
		public studentAuth: StudentAuthGuard,
		public teacherAuth: TeachAuthGuard,
		public adminAuth: AdminAuthGuard) {
		this.encryptMode = true;
	}

	ngOnInit() {
		if (this.adminAuth.isLoggedInAdmin === true || this.teacherAuth.isLoggedInTeacher === true || this.studentAuth.isLoggedInStudent === true) {
			this.router.navigate(['/home']);
		}
		this.model.userType = 'Student'
		$('.toggle-password').click(function () {
			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
	}

	convertTextL() {
		if (this.encryptMode) {
			this.encryptPswdL = CryptoJS.AES.encrypt(this.model.password.trim(),
				this.model.email.trim()).toString();
		}
	}

	onLoginEmailTeacher(): void {
		let keepGoing = true;
		firebase.auth().fetchSignInMethodsForEmail(this.model.email)
			.then((signInMethods) => {
				if (signInMethods.length) {
					this.authService.loginWithEmail(this.model.email, this.model.password)
						.then(() => {
							this.db.database.ref('user_register')
								.once('value')
								.then((snapshot) => {
									snapshot.forEach(data => {
										const key = data.key;
										const a = data.val().email;
										const b = data.val().password;
										const c = data.val().userType;
										const d = data.val().status;
										const e = data.val().userId;
										this.decryptPswdL = CryptoJS.AES.decrypt(b.trim(), a.trim()).toString(CryptoJS.enc.Utf8);
										if (a === this.model.email) {
											if (this.decryptPswdL === this.model.password && c === this.model.userType && d === 'Active') {

												localStorage.setItem('isLoggedInTeacher', "true");
												localStorage.setItem('currentTeacherToken', this.afAuth.auth.currentUser.uid);
												this.toastr.success(this.model.email + ' successfully login');
												this.router.navigate(['teacher-operators']);
											}
											else {
												if (this.decryptPswdL !== this.model.password) {
													this.toastr.error('The password is invalid or the user does not have a password.');
												}
												if (c !== this.model.userType) {
													this.toastr.error('User type is invalid. Please use valid user sign in form');
												}
												if (d !== 'Active') {
													this.toastr.error('User account is inactive.');
												}
											}
										}
									})
								})
						})
						.catch(error => {
							this.toastr.error(error.message);
						});
				} else {
					this.authService.signUpWithEmail(this.model.email, this.model.password)
						.then(() => {
							this.db.database.ref('user_register')
								.once('value')
								.then((snapshot) => {
									snapshot.forEach(data => {
										const key = data.key;
										const a = data.val().email;
										const b = data.val().password;
										const c = data.val().userType;
										const d = data.val().status;
										const e = data.val().userId;
										this.decryptPswdL = CryptoJS.AES.decrypt(b.trim(), a.trim()).toString(CryptoJS.enc.Utf8);
										if (keepGoing) {
											if (a === this.model.email) {
												if (this.decryptPswdL === this.model.password && c === this.model.userType && d === 'Active') {

													localStorage.setItem('isLoggedInTeacher', "true");
													localStorage.setItem('currentTeacherToken', this.afAuth.auth.currentUser.uid);
													this.toastr.success(this.model.email + ' successfully login');
													this.router.navigate(['teacher-operators']);
													const key = data.key;
													this.db.object('user_register/' + key).update({
														userId: this.afAuth.auth.currentUser.uid
													})
												}
												else {
													if (this.decryptPswdL !== this.model.password) {
														this.toastr.error('The password is invalid or the user does not have a password.');
													}
													if (c !== this.model.userType) {
														this.toastr.error('User type is invalid. Please use valid user sign in form');
													}
													if (d !== 'Active') {
														this.toastr.error('User account is inactive.');
													}

												}
												return keepGoing = false;
											}
										}
									})
									if (keepGoing === true) {
										this.toastr.error('There is no user record corresponding to this identifier. The user may have been deleted.')
									}
								})
						})
						.catch(error => {
							this.toastr.error(error.message);
						});
				}
			})
	}

	onLoginEmailStudent() {
		let keepGoing = true;
		firebase.auth().fetchSignInMethodsForEmail(this.model.email)
			.then((signInMethods) => {
				if (signInMethods.length) {
					this.authService.loginWithEmail(this.model.email, this.model.password)
						.then(() => {
							this.db.database.ref('user_register')
								.once('value')
								.then((snapshot) => {
									snapshot.forEach(data => {
										const key = data.key;
										const a = data.val().email;
										const b = data.val().password;
										const c = data.val().userType;
										const d = data.val().status;
										const e = data.val().userId;
										this.decryptPswdL = CryptoJS.AES.decrypt(b.trim(), a.trim()).toString(CryptoJS.enc.Utf8);
										if (a === this.model.email) {
											if (this.decryptPswdL === this.model.password && d === 'Active') {

												localStorage.setItem('isLoggedInStudent', "true");
												localStorage.setItem('currentStudentToken', this.afAuth.auth.currentUser.uid);
												this.toastr.success(this.model.email + ' successfully login');
												this.router.navigate(['operators']);
											}
											else {
												if (this.decryptPswdL !== this.model.password) {
													this.toastr.error('The password is invalid or the user does not have a password.');
												}
												if (d !== 'Active') {
													this.toastr.error('User account is inactive.');
												}
											}
										}
									})
								})
						})
						.catch(error => {
							this.toastr.error(error.message);
						});
				} else {
					this.authService.signUpWithEmail(this.model.email, this.model.password)
						.then(() => {
							this.db.database.ref('user_register')
								.once('value')
								.then((snapshot) => {
									snapshot.forEach(data => {
										const key = data.key;
										const a = data.val().email;
										const b = data.val().password;
										const c = data.val().userType;
										const d = data.val().status;
										const e = data.val().userId;
										this.decryptPswdL = CryptoJS.AES.decrypt(b.trim(), a.trim()).toString(CryptoJS.enc.Utf8);
										if (keepGoing) {
											if (a === this.model.email) {
												if (this.decryptPswdL === this.model.password && d === 'Active') {

													localStorage.setItem('isLoggedInStudent', "true");
													localStorage.setItem('currentStudentToken', this.afAuth.auth.currentUser.uid);
													this.toastr.success(this.model.email + ' successfully login');
													this.router.navigate(['operators']);
													const key = data.key;
													this.db.object('user_register/' + key).update({
														userId: this.afAuth.auth.currentUser.uid
													})
												}
												else {
													if (this.decryptPswdL !== this.model.password) {
														this.toastr.error('The password is invalid or the user does not have a password.');
													}
													if (d !== 'Active') {
														this.toastr.error('User acccount is inactive.');
													}
												}
												return keepGoing = false;
											}
										}
									})
									if (keepGoing === true) {
										this.toastr.error('There is no user record corresponding to this identifier. The user may have been deleted.')
									}
								})
						})
						.catch(error => {
							this.toastr.error(error.message);
						});
				}
			})
	}

}
